import { createSlice } from "@reduxjs/toolkit";

const viewGlossarySlice = createSlice({
  name: "items",
  initialState: {
    data: [],
    loading: false,
    error: null,
    isAlphaBtnHidden: false,
    currentPage: '',
  },
  reducers: {
    setItems: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    toggleAlphaBtn: (state) => {
      state.isAlphaBtnHidden = !state.isAlphaBtnHidden;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
});

export const {
  setItems,
  setLoading,
  setError,
  setPage,
  toggleAlphaBtn,
} = viewGlossarySlice.actions;
export default viewGlossarySlice.reducer;
