import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../Assets/logo.png";
import { breadcrumbItems } from "../../GLOBALDATA";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Input from "../Input/index";
import Nav from "../NavBar/Nav";
import Wrapper from "../Wrapper";

const StyledLogoBg = styled.div`
  text-align: left;
  border-top: 0;
  background-color: #b04a2a;
`;

const StyledTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledContainer = styled.div`
  padding: 0 30px;
  background-color: #fff;
  height: 48px;
`;
const StyledMainContainer = styled.div`
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 10;
  width: 100%;
`;

const DataPortal = styled.span`
  margin-right: 20px;
  color: #161616;
`;

const Mainmenu = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const BASE_URL = process.env.REACT_APP_ENVIRONMENT_BASE_URL;
  const handleSearch = () => {
    const searchURL =
      `${BASE_URL}/search-content?search=` + encodeURIComponent(searchQuery);
    window.location.href = searchURL;
  };
  const currentPage = useSelector(
    (state) => state.glossaryItems.currentPage
  );
  return (
    <StyledMainContainer>
      <StyledContainer className="mainMenu" id="mainMenu">
        <StyledTopBar>
          <Link to="https://www.nationalgrideso.com/">
            <img src={Logo} alt="logo" id="logo" />
          </Link>
          <StyledRightBlock>
            <Input
              onClickSearch={handleSearch}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              type={"search"}
              className="gcGlobalSearch"
              id="gcGlobalSearch"
            />
            <Link
              to={`${BASE_URL}/data-portal`}
              style={{
                color: "#fff",
                textDecoration: "none",
                fontSize: "14px",
                letterSpacing: "0.16px",
              }}
            >
              <DataPortal>Data Portal</DataPortal>
            </Link>
          </StyledRightBlock>
        </StyledTopBar>
      </StyledContainer>
      <StyledLogoBg>
        <Wrapper>
          <Nav />
        </Wrapper>
      </StyledLogoBg>
      {currentPage === 'VIEW_GLOSSARY' && (
        <div style={{ padding: "0 30px" }}>
          <Breadcrumb items={breadcrumbItems} />
        </div>
      )}
    </StyledMainContainer>
  );
};

export default Mainmenu;
