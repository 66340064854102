import React from 'react';
import styled from "styled-components";

const StyledWrapper = styled.div`
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
`;

const Wrapper = ({children}) => {
    return (
        <StyledWrapper className="wrapper" id="wrapper">{children}</StyledWrapper>
    )
};

export default Wrapper;