import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BannerImage from "../../Assets/banner.jpg";

const BannerImgstyle = styled.div`
  font-size: 12px;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  position: relative;
`;

const BannerHeader = styled.div`
  background: #ededed;
  text-align: left;
  padding: 30px;
  position: absolute;
  bottom: -50px;
  left: 30px;
  width: 500px;
`;

const BannerHeading = styled.h1`
  font-size: 48px;
  margin-top: 0;
`;

const BackToTopLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  color: #b04a2a;
  text-decoration: none;
`;

const Banner = ({ backToGridCodehandler }) => {
  return (
    <BannerImgstyle
      data-testid="bannerComp"
      className="bannerImgstyle"
      id="bannerImgstyle"
    >
      <img
        className="glossaryBanner"
        id="glossaryBanner"
        src={BannerImage}
        alt="Glossary Banner"
        width="100%"
      />
      <BannerHeader className="bannerHeader" id="bannerHeader">
        <BannerHeading className="bannerHeading" id="bannerHeading">
          Welcome to the <br />
          Grid Code Glossary
        </BannerHeading>
        <BackToTopLink
          className="backToTopLink"
          id="backToTopLink"
          primary
          to="/"
          onClick={backToGridCodehandler}
        >
          Back to Grid Code
        </BackToTopLink>
      </BannerHeader>
    </BannerImgstyle>
  );
};

export default Banner;
