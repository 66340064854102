import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background: ${({ primary }) => (primary ? "#b04a2a" : "#fff")};
  color: ${({ primary }) => (primary ? "#fff" : "#b04a2a")};
  font-size: 14px;
  padding: ${({ type }) => (type === "ICON" ? "16px 19px" : "15px 24px")};
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  margin-left: ${({ marg }) => (marg ? "10px" : "0")};
  margin-top: 20px;
  margin-bottom: 3px;
  font-weight: 700;
  &:hover {
    background: #9e4326;
  }
`;

const Button = ({
  children,
  type,
  disabled,
  value,
  onClick,
  className,
  id,
  primary,
  marg,
  dataTestId,
}) => {
  return (
    <StyledButton
      type={type}
      disabled={disabled}
      value={value}
      onClick={onClick}
      className={className}
      primary={+!!primary}
      marg={+!!marg}
      id={id}
      data-testid={dataTestId}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
