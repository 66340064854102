import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Mainmenu from "../Components/Mainmenu";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { errorPageContent } from "../GLOBALDATA";
import Footer from "../Components/Footer";
import { useDispatch } from "react-redux";
import { setPage } from "../store/viewGlossary-slice";

const StyledBredcrumb = styled.div`
  padding: 0 30px;
`;

const StyledPageNotFoundText = styled.span`
  font-size: 3rem;
  line-height: 1.16;
`;

const StyledMainContainer = styled.div`
  margin: auto 30px;
  & h1 {
    margin-top: 0;
  }
`;

const StyledSearchText = styled.span`
  color: #b04a2a;
  padding: 5px;
  & a {
    text-decoration: none;
    color: #b04a2a;
  }
`;

const StyledKeyArea = styled.h2`
  font-size: 1.25rem;
  line-height: 1.29;
  font-weight: normal;
`;

const StyledCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
`;

const StyledCard = styled.div`
  border-top: 1px solid #ffbf22;
  flex: 1;
  min-height: 10em;
`;

const StyledTitlePara = styled.p`
  height: 30px;
  margin: 10px 0px;
`;

const StyledTitle = styled.span`
  color: red;
  & a {
    text-decoration: none;
    color: #b04a2a;
    line-height: 25px;
    font-weight: 700;
    font-size: 20px;
  }
`;

const ErrorPageMainDiv = styled.div`
  margin-top: 108px;
`;

const ErrorLayout = () => {
  const breadcrumbItems = [
    { text: "Home", url: "https://www.nationalgrideso.com" },
    {
      text: "Page Not Found",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('ERROR'));
  }, [dispatch]);
  
  return (
    <>
      <Mainmenu />
      <ErrorPageMainDiv>
        <StyledBredcrumb>
          <Breadcrumb items={breadcrumbItems} />
        </StyledBredcrumb>
        <StyledMainContainer>
          <div>
            <h1>
              <StyledPageNotFoundText>Page Not Found</StyledPageNotFoundText>
            </h1>
          </div>
          <div>
            <p>We couldn't find this page.</p>
            <p>
              Please try
              <StyledSearchText>
                <Link to={errorPageContent.searchLink}>searching</Link>
              </StyledSearchText>
              using different terms to help you find what you were looking for.
            </p>
          </div>
          <div>
            <StyledKeyArea>Key areas of our site</StyledKeyArea>
            <StyledCardContainer>
              {errorPageContent.keyAreas.map((card) => {
                return (
                  <StyledCard>
                    <section>
                      <StyledTitlePara>
                        <StyledTitle href="/industry-information/balancing-services">
                          <Link to={card.link}>{card.title}</Link>
                        </StyledTitle>
                      </StyledTitlePara>
                      <div>
                        <Link to={card.link}>
                          <img
                            loading="lazy"
                            src={card.image}
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "auto",
                            }}
                            alt={card.imageAlt}
                          />
                        </Link>
                      </div>
                      <div>
                        <div>
                          <p>{card.content}</p>
                        </div>
                      </div>
                    </section>
                  </StyledCard>
                );
              })}
            </StyledCardContainer>
          </div>
        </StyledMainContainer>
      </ErrorPageMainDiv>
      <Footer />
    </>
  );
};

export default ErrorLayout;
