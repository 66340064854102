import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FooterLogo from "../../Assets/footerLogoWhite.svg";
import {
  footerBottomLinks,
  footerLinks,
  footerSocialMediaIcons,
} from "../../GLOBALDATA";

const FooterBackground = styled.div`
  background-color: #525252;
  padding: 30px;
  margin-top: 30px;
  z-index: 10;
  position: ${({ isGridCodePage }) => (isGridCodePage ? "absolute" : "static")};
  bottom: 0;
  left: 0;
  right: 0;
`;

const ParentFlex = styled.div`
  display: flex;
`;

const Heading = styled.h3`
  color: #eabc4a;
  margin-top: 0;
`;

const UnorderedList = styled.ul`
  padding-left: 0;

  &.footerSecondRowLinks {
    display: flex;
    margin: 0;
    & li {
      margin-bottom: 0;
      & a {
        padding: 0 24px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 2px;
          right: 0;
          height: 85%;
          width: 1px;
          background-color: #fff;
        }
      }
      &:last-child a::after {
        content: none;
      }
      &:first-child a {
        padding-left: 0;
      }
    }
  }

  &.footerthirdRowLinks {
    display: flex;
    align-items: center;
    margin: 0;
    & li {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }
`;

const ListedLink = styled.li`
  list-style: none;
  margin-bottom: 10px;
      & a {
        text-decoration: none;
        color: #fff;
        line-height: 26px;
        font-weight: 400;
        font-size: 14px;
    }
  }
`;

const ColumnDiv = styled.div`
  width: 33.33%;
  position: relative;
  padding-left: 30px;
  &:first-child {
    padding-left: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #fff;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

const SecondRowLinks = styled.div`
  margin-top: 30px;
`;

const ThirdRowLinks = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Footer = () => {
  const currentPage = useSelector(
    (state) => state.glossaryItems.currentPage
  );

  return (
    <FooterBackground
      className="footerBackground"
      id="footerBackground"
      isGridCodePage={currentPage === 'GRID_CODE'}
    >
      <ParentFlex className="parentFlex" id="parentFlex">
        {footerLinks?.map(({ heading, body }) => (
          <ColumnDiv key={heading}>
            <Heading className="heading" id="heading">
              {heading}
            </Heading>
            <UnorderedList className="unorderedList" id="unorderedList">
              {body?.map(({ name, link }) => (
                <ListedLink key={`${name}_${link}`}>
                  <Link to={link}>{name}</Link>
                </ListedLink>
              ))}
            </UnorderedList>
          </ColumnDiv>
        ))}
      </ParentFlex>

      <SecondRowLinks className="secondRowLinks" id="secondRowLinks">
        <UnorderedList
          className="footerSecondRowLinks"
          id="footerSecondRowLinks"
        >
          {footerBottomLinks?.map(({ name, link }) => (
            <ListedLink key={`${name}_${link}`}>
              <Link to={link}>{name}</Link>
            </ListedLink>
          ))}
        </UnorderedList>
      </SecondRowLinks>

      <ThirdRowLinks className="thirdRowLinks" id="thirdRowLinks">
        <div>
          <UnorderedList
            className="footerthirdRowLinks"
            id="footerthirdRowLinks"
          >
            {footerSocialMediaIcons?.map(({ name, link, icon }) => (
              <ListedLink key={`${name}_${link}`}>
                <Link to={link}>
                  <img src={icon} alt={name} />
                </Link>
              </ListedLink>
            ))}
          </UnorderedList>
        </div>
        <div>
          <img
            src={FooterLogo}
            alt="National Grid ESO"
            className="footerLogo"
            id="footerLogo"
          />
        </div>
      </ThirdRowLinks>
    </FooterBackground>
  );
};

export default Footer;
