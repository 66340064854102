import React from "react";
import styled from "styled-components";
import Wrapper from "../Wrapper";
import { setActivetab } from "../../store/tabs-slice";
import { useDispatch, useSelector } from "react-redux";

const TabsContainer = styled.div`
  margin-top: 20px;
`;

const TabButton = styled.button`
  flex: 1;
  cursor: pointer;
  border: none;
  text-align: left;
  position: relative;
  border-bottom: 2px solid #e0e0e0;
  padding: 15px;
  max-width: 150px;
  width: 100%;
  background-color: initial;
  font-size: 14px;
  font-weight: 400;

  &.active {
    border-bottom: 2px solid #b04a2a;
  }
`;

const TabContent = styled.div``;

const StyledCenterDiv = styled.div`
  text-align: center;
`;

const Tabs = ({ children }) => {
  const dispatch = useDispatch();

  const { getActiveTab } = useSelector((state) => ({
    getActiveTab: state?.getActiveTab?.activeTab,
  }));

  const handleTabClick = (index) => {
    dispatch(setActivetab(index));
  };

  const tabs = React.Children.toArray(children);

  return (
    <Wrapper>
      <StyledCenterDiv className="tabs" id="tabs">
        <TabsContainer>
          {tabs?.map((tab, index) => (
            <TabButton
              key={tab.props.label}
              onClick={() => handleTabClick(index)}
              className={getActiveTab === index ? "active" : ""}
              id={getActiveTab === index ? "active" : ""}
            >
              {tab.props.label}
            </TabButton>
          ))}
        </TabsContainer>
      </StyledCenterDiv>
      <TabContent>
        {tabs && tabs[getActiveTab] && tabs[getActiveTab].props.children}
      </TabContent>
    </Wrapper>
  );
};

export default Tabs;
