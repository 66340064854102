import { fetchgridCodeSidebarMenus } from "./api";
import { setSidebarItems, setSidebarLoading, setSidebarError } from "./slice";

export const fetchGridcodeSidebarData = () => async (dispatch) => {
  try {
    dispatch(setSidebarLoading());
    const response = await fetchgridCodeSidebarMenus();
    if (response.hasOwnProperty("error")) {
      dispatch(setSidebarError(response.error));
    } else {
      dispatch(setSidebarItems(response));
    }
  } catch (error) {
    dispatch(setSidebarError(error.message));
  }
};
