import parse from "html-react-parser";
import Tooltip from "../Tooltip";
import { useState } from "react";

const ParsedHtmlWithTooltip = ({
  value_text,
  section,
  subSec,
  secId,
  currentSecIdForTooltip,
  setCurrentSecIdForTooltip,
}) => {
  const [activeTooltip, setActiveTooltip] = useState("");

  let uniqueId = 0;
  let styles = {};
  if (subSec.endsWith(".0")) {
    styles["textAlign"] = "center";
  }
  return (
    <div
      id={section + "-" + subSec.replace(".", "-")}
      className="whitespace-pre-wrap text-justify"
      style={styles}
    >
      {parse(
        value_text.replace(
          /\*\*(image\d+)\*\*/g,
          `<img src="${process.env.PUBLIC_URL}/gcdocs/${section}/image/$1.PNG" alt="$1">`
        ),
        {
          replace(domNode) {
            if (
              domNode?.type === "tag" &&
              domNode?.name === "strong" &&
              Array.isArray(domNode?.children) &&
              domNode?.children?.length === 1 &&
              domNode?.children[0]?.type === "text" &&
              typeof domNode?.children[0]?.data === "string"
            ) {
              const data = "" + domNode?.children[0]?.data;
              uniqueId++;
              return (
                <Tooltip
                  showTooltip={
                    secId === currentSecIdForTooltip &&
                    activeTooltip === `${uniqueId}`
                  }
                  uniqueId={`${uniqueId}`}
                  data={data}
                  setActiveTooltip={setActiveTooltip}
                  secId={secId}
                  setCurrentSecIdForTooltip={setCurrentSecIdForTooltip}
                />
              );
            }
          },
        }
      )}
    </div>
  );
};

export default ParsedHtmlWithTooltip;
