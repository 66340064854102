import {createSlice} from '@reduxjs/toolkit';

const accordionToggleSlice = createSlice({
    name: 'accordion',
    initialState: {accordionIsVisible: false},
    reducers: {
        toggle(state) {
            state.accordionIsVisible = !state.accordionIsVisible;
        }
    }
});

export const uiActions = accordionToggleSlice.actions;
export default accordionToggleSlice;