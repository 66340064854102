import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../AppInsights";

export const fetchTooltipExplainationData = async (key, signal) => {
  appInsights.trackEvent({
    name: "API request",
    properties: {
      apiName: "Fetch Tooltip Bold Text",
      url: `api/Glossary/GetBoldText?keyword=${key}`,
    },
  });

  let data = [];
  let response;
  try {
    response = await fetch(
      `api/Glossary/GetBoldText?keyword=${encodeURIComponent(key)}`,
      { signal }
    );
    data = await response.json();
  } catch (err) {
    appInsights.trackException({
      error: new Error(err),
      severityLevel: SeverityLevel.Error,
    });

    return { error: { name: err?.name, message: err?.message } };
  }

  if (data.error) {
    return { error: data.error };
  } else {
    return data;
  }
};
