import React from "react";
import styled from "styled-components";

const LoaderParentDiv = styled.div`
  min-height: 100vh;
`;

const LoaderDiv = styled.div`
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  position: absolute;
  left: 50%;
  top: 40%;

  @-webkit-keyframes rotate {
    /* 100% keyframe for  clockwise. 
           use 0% instead for anticlockwise */
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  &.rightSideglossaryLoader {
    left: 45%;
    top: 0;
  }
`;

const Loader = ({ className, id }) => {
  return (
    <LoaderParentDiv>
      <LoaderDiv className={className} id={id} data-testid="loader" />
    </LoaderParentDiv>
  );
};

export default Loader;
