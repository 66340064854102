import parse from "html-react-parser";

const ParsedHtml = ({ value_text, section, subSec }) => {
  return (
    <div className="whitespace-pre-wrap text-justify">
      {parse(
        value_text.replace(
          /\*\*(image\d+)\*\*/g,
          `<img src="${process.env.PUBLIC_URL}/gcdocs/${section}/image/$1.PNG" alt="$1">`
        )
      )}
    </div>
  );
};

export default ParsedHtml;
