import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "../Pagination";
import SearchResultCard from "./SearchResultCard";

const WrapperBody = styled.div`
  padding: 17px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
  margin-top: 148px;
`;

const TotalResultText = styled.p`
  color: #006dae;
`;

const StyledPaginationRight = styled.div`
  padding-left: 30px;
  padding-right: 18px;
  display: flex;
  justify-content: right;
  margin-bottom: 30px;
`;

const SearchResults = ({
  searchedArr,
  contentSearchedValue,
  searchedResultsHandler,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsForCurrentPage, setItemsForCurrentPage] = useState([]);
  const itemsPerPage = 8;

  const paginationHandler = (pageNum) => {
    const alphabeticalOrderByPagination = [];
    const startIndex = (pageNum - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    setCurrentPage(pageNum);

    setItemsForCurrentPage(
      alphabeticalOrderByPagination?.length === 0
        ? searchedArr?.slice(startIndex, endIndex)
        : alphabeticalOrderByPagination?.slice(startIndex, endIndex)
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (searchedArr?.length > 0) {
      setItemsForCurrentPage(searchedArr?.slice(0, 8));
      setCurrentPage(1);
    }
  }, [searchedArr]);

  return (
    <>
      <WrapperBody
        className="gridCodeSeachedResults tooltipOnHover"
        id="gridCodeSeachedResults"
      >
        <TotalResultText className="totalResultText" id="totalResultText">
          Total result(s) found{" "}
          <span>{searchedArr?.length > 0 && searchedArr?.length}</span>
        </TotalResultText>
        {searchedArr?.length > 0 &&
          itemsForCurrentPage?.map((item, index) => (
            <SearchResultCard
              key={`${item?.Id}`}
              item={item}
              index={index}
              searchedArr={searchedArr}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              contentSearchedValue={contentSearchedValue}
              searchedResultsHandler={searchedResultsHandler}
            />
          ))}
      </WrapperBody>
      <StyledPaginationRight>
        {searchedArr?.length >= 8 ? (
          <Pagination
            totalPages={Math.ceil(searchedArr?.length / itemsPerPage)}
            onPageChange={paginationHandler}
            testIdName="pagination-data"
            currentPage={currentPage}
          />
        ) : (
          <></>
        )}
      </StyledPaginationRight>
    </>
  );
};

export default SearchResults;
