import { createSlice } from '@reduxjs/toolkit';

const gcSubSectionSlice = createSlice({
  name: 'gcSubSectionData',
  initialState: {
    gcSubSectionArr: [],
    gcSubSectionLoading: false,
    gcSubSectionError: null,
  },
  reducers: {
    setgcSubSectionItems: (state, action) => {
      state.gcSubSectionArr = action.payload;
      state.gcSubSectionLoading = false;
      state.gcSubSectionError = null;
    },
    setgcSubSectionLoading: (state) => {
      state.gcSubSectionLoading = true;
    },
    setgcSubSectionError: (state, action) => {
      state.gcSubSectionLoading = false;
      state.gcSubSectionError = action.payload;
    },
  },
});

export const { setgcSubSectionItems, setgcSubSectionLoading, setgcSubSectionError } = gcSubSectionSlice.actions;
export default gcSubSectionSlice.reducer;
