// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p,
li {
  line-height: 1.7;
}

iframe {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify-content {
  justify-content: space-between;
}

.justify-content-right {
  justify-content: right;
}

.align-items {
  align-items: center;
}

table {
  display: block;
  border-collapse: collapse;
  text-align: left;
}

th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px 10px;
  text-align: left;
  line-height: 1.8;
}

th,
th div strong {
  color: #000 !important;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.position-relative {
  position: relative;
}

.text-justify {
  text-align: justify;
}

mjx-container[jax="CHTML"][display="true"] {
  display: block;
  text-align: left !important;
  margin: 0 !important;
}
#CO-CO-1,
#CO-CO-2,
#CO-CO-3,
#CO-CO-4 {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,uBAAuB;EACvB,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,oBAAoB;AACtB;AACA;;;;EAIE,kBAAkB;AACpB","sourcesContent":["@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\np,\nli {\n  line-height: 1.7;\n}\n\niframe {\n  display: none;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\n.flex {\n  display: flex;\n}\n\n.justify-content {\n  justify-content: space-between;\n}\n\n.justify-content-right {\n  justify-content: right;\n}\n\n.align-items {\n  align-items: center;\n}\n\ntable {\n  display: block;\n  border-collapse: collapse;\n  text-align: left;\n}\n\nth,\ntd {\n  border: 1px solid black;\n  border-collapse: collapse;\n  padding: 5px 10px;\n  text-align: left;\n  line-height: 1.8;\n}\n\nth,\nth div strong {\n  color: #000 !important;\n}\n\n.p-0 {\n  padding: 0;\n}\n\n.m-0 {\n  margin: 0;\n}\n\n.mt-0 {\n  margin-top: 0 !important;\n}\n\n.position-relative {\n  position: relative;\n}\n\n.text-justify {\n  text-align: justify;\n}\n\nmjx-container[jax=\"CHTML\"][display=\"true\"] {\n  display: block;\n  text-align: left !important;\n  margin: 0 !important;\n}\n#CO-CO-1,\n#CO-CO-2,\n#CO-CO-3,\n#CO-CO-4 {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
