import React from "react";
import styled from "styled-components";
import SearchIconWhite from "../../Assets/searchWhite.svg";
import SearchIconBlack from "../../Assets/searchIconBlack.svg";

const StyledInput = styled.input`
  padding: ${({ isglossary }) =>
    isglossary ? `12px 8px 12px 15px` : `11px 0 4px 0`};
  border: ${({ isglossary }) => (isglossary ? `1.5px solid #8d8d8d` : `0`)};
  border-bottom: ${({ isglossary }) =>
    isglossary ? null : `1px solid #393939`};
  border-radius: ${({ isglossary }) => (isglossary ? `2px 0 0 2px` : `0`)};
  margin-bottom: ${({ isglossary }) => (isglossary ? `0px` : `9px`)};
  width: ${({ isglossary }) => (isglossary ? `85%;` : `225px`)};
  font-size: 14px;
  height: ${({ isglossary }) => (isglossary ? `40px` : `36px`)};
  background-color: transparent;
  color: #161616;

  &::placeholder {
    color: #a8a8a8;
  }

  &::-ms-input-placeholder {
    color: #a8a8a8;
  }

  &:focus {
    ${({ isglossary, iserror }) => {
      let borderColor;
      if (isglossary) {
        borderColor = iserror ? "#cf0b0d" : "#3394cd";
      }

      if (isglossary) {
        return `border: 1.5px solid ${borderColor};`;
      }
      return `border-bottom: 1px solid #000`;
    }}
  }

  &:focus-visible {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: ${({ iserror }) =>
      iserror
        ? `url("assets/warning--filled.svg")`
        : `url("assets/close.svg")`};
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      background-image: url("assets/close.svg");
    }
  }
`;

const StyledImgDiv = styled.div`
  &.glossarySearch {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b04a2a;
    border-radius: 0 2px 2px 0;
    cursor: ${({ enable }) => (enable ? "pointer" : "not-allowed")};
  }

  &.headerSearch {
    padding-left: 12px;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = React.forwardRef(
  (
    {
      onChange,
      onFocus = () => {},
      onEnter = () => {},
      type,
      value,
      className,
      placeholder,
      name,
      isGlossary,
      isError = false,
      onClickSearch,
      maxLength = 100,
      isEnabled = true,
      id,
    },
    ref
  ) => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && typeof onEnter === "function") {
        onEnter();
      }
    };

    return (
      <StyledFlex>
        <StyledInput
          isglossary={+!!isGlossary}
          onChange={onChange}
          onFocus={onFocus}
          type={type}
          value={value}
          className={className}
          placeholder={placeholder}
          name={name}
          onKeyDown={handleKeyDown}
          iserror={+!!(type?.toLowerCase() === "search" && isError)}
          maxLength={maxLength}
          ref={ref}
          id={id}
          autoComplete="off"
        />
        <StyledImgDiv
          onClick={onClickSearch}
          className={isGlossary ? "glossarySearch" : "headerSearch"}
          enable={+(isEnabled && !!value?.trim())}
        >
          <img
            alt="Search Icon"
            src={isGlossary ? SearchIconWhite : SearchIconBlack}
          />
        </StyledImgDiv>
      </StyledFlex>
    );
  }
);

export default Input;
