import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    // TODO : keep this for default tracking
    // extensionConfig: {
    //   // eslint-disable-next-line no-restricted-globals
    //   [reactPlugin.identifier]: { history: history },
    // },
  },
});
ai.loadAppInsights();

const withAppInsights = (Component) => withAITracking(reactPlugin, Component);
export default withAppInsights;
export const appInsights = ai.appInsights;
