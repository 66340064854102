import { fetchgcSubSectionData } from "./api";
import {
  setgcSubSectionItems,
  setgcSubSectionLoading,
  setgcSubSectionError,
} from "./slice";

export const fetchgcSubSectionMenuData = (key) => async (dispatch) => {
  try {
    dispatch(setgcSubSectionLoading());
    const response = await fetchgcSubSectionData(key);
    if (response.hasOwnProperty("error")) {
      dispatch(setgcSubSectionError(response.error));
    } else {
      dispatch(setgcSubSectionItems(response));
    }
  } catch (error) {
    dispatch(setgcSubSectionError(error.message));
  }
};
