import TwitterIcon from "./Assets/twitterIcon.svg";
import LinkedInIcon from "./Assets/linkedInIcon.svg";
import YoutubeIcon from "./Assets/youtubeIcon.svg";

import KeyAreaImageOne from "./Assets/Error/hot-and-industry-impacts.jpg";
import KeyAreaImageTwo from "./Assets/Error/wind-turbine-fields-in-cornwall.jpg";
import KeyAreaImageThree from "./Assets/Error/fes-banner-2022.jpg";
import KeyAreaImageFour from "./Assets/Error/mother-and-child-cooking.jpg";

const BASE_URL = process.env.REACT_APP_ENVIRONMENT_BASE_URL;

export const aboutLinks = {
  heading: "About us",
  body: [
    { name: "Who we are", link: `${BASE_URL}/what-we-do` },
    {
      name: "Our strategy",
      link: `${BASE_URL}/our-strategy`,
    },
    {
      name: "Careers with us",
      link: `${BASE_URL}/what-we-do/our-people/work-us`,
    },
  ],
};

export const getInTouchLinks = {
  heading: "Get in touch",
  body: [
    {
      name: "Contact us",
      link: `${BASE_URL}/get-touch/contact-us`,
    },
    {
      name: "Report a power cut",
      link: `${BASE_URL}/contact-us/power-cuts`,
    },
    {
      name: "Media centre",
      link: `${BASE_URL}/news-and-events/media-centre`,
    },
  ],
};

export const youMayAlsoLinks = {
  heading: "You may also be interested in",
  body: [
    { name: "National Grid PLC", link: `${BASE_URL}` },
    { name: "Investors", link: `https://www.nationalgrid.com/investors` },
  ],
};

export const footerBottomLinks = [
  {
    name: "Accessibility",
    link: `${BASE_URL}/accessibility`,
  },
  {
    name: "Privacy policy",
    link: `${BASE_URL}/privacy-policy`,
  },
  {
    name: "Cookie policy",
    link: `${BASE_URL}/cookie-policy`,
  },
  {
    name: "Terms and Conditions ",
    link: `${BASE_URL}/terms-and-conditions`,
  },
  { name: "Security", link: `${BASE_URL}/security` },
  {
    name: "Modern slavery statement",
    link: `${BASE_URL}/modern-slavery-statement`,
  },
];

export const footerSocialMediaIcons = [
  {
    name: "Twitter",
    link: "https://x.com/NationalGridESO",
    icon: TwitterIcon,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/nationalgrideso/",
    icon: LinkedInIcon,
  },
  {
    name: "Youtube",
    link: "https://www.youtube.com/channel/UCh7g68ZFu8W2zaSUdAHNs7Q",
    icon: YoutubeIcon,
  },
];

export const footerLinks = [aboutLinks, getInTouchLinks, youMayAlsoLinks];

export const breadcrumbItems = [
  { text: "Home", url: `${BASE_URL}` },
  {
    text: "Industry Information",
    url: `${BASE_URL}/industry-information`,
  },
  {
    text: "Codes",
    url: `${BASE_URL}/industry-information/codes`,
  },
  {
    text: "Grid Code(GC)",
    url: `${BASE_URL}/industry-information/codes/grid-code-gc`,
  },
  {
    text: "Grid Code Documents",
    url: `${BASE_URL}/industry-information/codes/grid-code-gc/grid-code-documents`,
  },
  {
    text: "The Complete Grid Code",
  },
];
export const BreadcrumbDropdownlinks = [
  { label: "Home", url: `${BASE_URL}` },
  {
    label: "Industry Information",
    url: `${BASE_URL}/industry-information`,
  },
  {
    label: "Codes",
    url: `${BASE_URL}/industry-information/codes`,
  },
  {
    label: "Grid Code(GC)",
    url: `${BASE_URL}/industry-information/codes/grid-code-gc`,
  },
  {
    label: "The Complete Grid Code",
  },
];

export const errorPageContent = {
  searchLink: "https://www.nationalgrideso.com/search-content",
  keyAreas: [
    {
      title: " Balancing services",
      link: "https://www.nationalgrideso.com/industry-information/balancing-services",
      image: KeyAreaImageOne,
      imageAlt:
        "National Grid ESO - Early competition project updates - Sunset",
      content: `We procure services to balance demand and supply and to ensure the security and quality of electricity supply across Britain's transmission system.`,
    },
    {
      title: "Codes",
      link: "https://www.nationalgrideso.com/industry-information/codes",
      image: KeyAreaImageTwo,
      imageAlt: "Bridging the gap to Net Zero Wind turbine fields in Cornwall",
      content: `We are administrators for the Connection and Use of System Code (CUSC), the Grid Code (GC), the System Operator – Transmission Owner Code (STC), and the Security & Quality of Supply Standard (SQSS).`,
    },
    {
      title: "Future Energy Scenarios",
      link: "https://www.nationalgrideso.com/future-energy/future-energy-scenarios-fes",
      image: KeyAreaImageThree,
      imageAlt: "",
      content: `Future Energy Scenarios (FES) represent a range of different, credible ways to decarbonise our energy system as we strive towards the 2050 target.`,
    },
    {
      title: "Our progress towards net zero",
      link: "https://www.nationalgrideso.com/future-energy/our-progress-towards-net-zero",
      image: KeyAreaImageFour,
      imageAlt:
        "National Grid ESO - how does COVID affect the electricity grid during Easter - mother and child cooking",
      content: `We’ve come a long way from the early 2000s when the nation was mostly powered by coal and gas.`,
    },
  ],
};
