import { fetchgcSectionData } from "./api";
import {
  setgcSectionItems,
  setgcSectionLoading,
  setgcSectionError,
  setgcUpdateDate,
} from "./slice";

export const fetchgcSectionMenusData = (key) => async (dispatch) => {
  try {
    dispatch(setgcSectionLoading());
    const response = await fetchgcSectionData(key);
    if (response.hasOwnProperty("error")) {
      dispatch(setgcSectionError(response.error));
    } else {
      if (key === "CO") {
        const CO4 = (response || []).find((GC) => GC?.subsection === "CO.4");

        dispatch(setgcUpdateDate(CO4?.revision_date));
      }
      dispatch(setgcSectionItems(response));
    }
  } catch (error) {
    dispatch(setgcSectionError(error.message));
  }
};
