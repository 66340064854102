import { configureStore } from "@reduxjs/toolkit";
import accordionToggleSlice from "./accordionToggle-slice";
import itemsReducer from "./viewGlossary-slice";
import subsectionReducer from "./selectedSubsection-slice";
import gridCodeData from "./gridCode/slice";
import sidebarMenuData from "./sidebar/slice";
import gcSectionMenusData from "./gcSection/slice";
import gcSubSectionMenuData from "./gcSubSection/slice";
import backGridCode from "./backToGridCode-slice";
import getGCKeySlice from "./getGCKey/slice";
import tabsSlice from "./tabs-slice";

const store = configureStore({
  reducer: {
    accordion: accordionToggleSlice.reducer,
    glossaryItems: itemsReducer,
    subsection: subsectionReducer,
    gridCodeBodyData: gridCodeData,
    sideMenuData: sidebarMenuData,
    gcSectionMenus: gcSectionMenusData,
    gcSubSectionMenus: gcSubSectionMenuData,
    backGridCodeButton: backGridCode,
    getGCKeySearch: getGCKeySlice,
    getActiveTab: tabsSlice,
  },
});

export default store;
