import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
const StyledFilterbtnsParent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  &.glossaryRightButtons {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    gap: 12px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    & button {
      width: 48px;
      height: 48px;
    }
  }
`;
const StyledFilterBtns = styled.button`
  width: 40px;
  height: 40px;
  margin: 0;
  text-align: center;
  line-height: 30px;
  padding: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  background-color: ${({ isactive }) => (isactive ? "#72301b" : "#b04a2a")};
  padding: 0.25em 1em;
  border: 0;
  &: last-of-type {
    margin-right: 0;
  }
  &.active {
    background: #72301b;
  }
  &:hover {
    background: #9e4326;
  }
`;
const AlphabeticalButtons = ({
  alphabeticalHandler,
  buttonsParentClassName,
  currentAlphabet = "All",
}) => {
  const alphabetsArr = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const currentPage = useSelector(
    (state) => state.glossaryItems.currentPage
  );
  return (
    <StyledFilterbtnsParent
      className={buttonsParentClassName}
      data-testid="button"
    >
      {[...(currentPage === 'VIEW_GLOSSARY' ? ["All"] : []), ...alphabetsArr].map((alphabet) => (
        <StyledFilterBtns
          key={alphabet}
          onClick={() => alphabeticalHandler(alphabet)}
          isactive={+(alphabet === currentAlphabet)}
          className={`alphabet_${alphabet}`}
          id={`alphabet${alphabet}`}
        >
          {alphabet}
        </StyledFilterBtns>
      ))}
    </StyledFilterbtnsParent>
  );
};
export default AlphabeticalButtons;
