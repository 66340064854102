import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DownArrow from "../../Assets/downArrowOrrange.svg";
import UpArrow from "../../Assets/upArrowOrrange.svg";
import RightActiveArrow from "../../Assets/rightActiveIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSectionName,
  setIsSearchedResult,
  setIsSectionOrSubSection,
  setSelectedSectionId,
  setSelectedSubSectionId,
  setSelectedSubsection,
} from "../../store/selectedSubsection-slice";

const StyledUl = styled.ul`
  list-style: none;
  color: #b04a2a;
  padding-left: 27px;
`;

const StyledLi = styled.li`
  padding-top: 13px;
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.subMenuPadd {
    padding-right: 5px;
  }
`;

const BoldSpan = styled.div`
  font-weight: 700;
  max-width: 90%;
`;

const StyledSubsection = styled.div`
  max-width: 90%;
`;

const SidebarMenuItem = ({
  section,
  prevAndNextBtnHide,
  setPrevAndNextBtnHide,
  isAllCollapsed,
  isAllExpanded,
  setIsAllCollapsed,
  setIsAllExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAllExpanded) {
      setIsExpanded(true);
      setIsAllExpanded(false);
    }
  }, [isAllExpanded, setIsAllExpanded]);

  useEffect(() => {
    if (isAllCollapsed) {
      setIsExpanded(false);
      setIsAllCollapsed(false);
    }
  }, [isAllCollapsed, setIsAllCollapsed]);

  const { sideMenuData, selectSubSectionId, selectedSectionId } = useSelector(
    (state) => ({
      sideMenuData: state?.sideMenuData?.sideMenuData,
      selectSubSectionId: state?.subsection?.selectedSubSectionId,
      selectedSectionId: state?.subsection?.selectedSectionId,
    })
  );

  useEffect(() => {
    if (section?.sec_id === selectedSectionId) {
      setIsExpanded(true);
    }
  }, [section?.sec_id, selectedSectionId, selectSubSectionId]);

  const subSecData = useMemo(() => {
    return Array.isArray(sideMenuData)
      ? sideMenuData?.filter(
          (submenu) =>
            submenu?.sec_id === section?.sec_id && submenu.subsec_ind === "Y"
        )
      : [];
  }, [section?.sec_id, sideMenuData]);

  const toggleHandler = (e) => {
    e.stopPropagation();
    setIsExpanded((isExpanded) => !isExpanded);
  };

  // GC Section Handlers Begin //
  const gcSectionHandler = (section, event) => {
    dispatch(setActiveSectionName(section?.sec_id));
    dispatch(setSelectedSectionId(section?.sec_id));
    dispatch(setIsSearchedResult(false));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const sectionHandler = (section) => {
    setIsExpanded(true);
    dispatch(setSelectedSectionId(section?.sec_id));
    dispatch(setIsSectionOrSubSection({ section: true, subSection: false }));
    if (section.sec_id === "P") {
      setPrevAndNextBtnHide({ prevButton: false, nextButton: true });
    } else {
      setPrevAndNextBtnHide({ prevButton: true, nextButton: true });
    }
    dispatch(setSelectedSubSectionId(null));
    dispatch(setIsSearchedResult(false));
  };

  // GC Section Handlers End //
  const subsectionHandler = (subSection) => {
    dispatch(setActiveSectionName(null));
    dispatch(setSelectedSubsection(subSection.subsec_id));
    dispatch(setSelectedSubSectionId(subSection.subsec_id));
    dispatch(setIsSectionOrSubSection({ section: false, subSection: true }));
    dispatch(setIsSearchedResult(false));

    if (
      sideMenuData[sideMenuData?.length - 1]?.subsec_id === subSection.subsec_id
    ) {
      setPrevAndNextBtnHide({ ...prevAndNextBtnHide, nextButton: false });
    } else {
      setPrevAndNextBtnHide({ prevButton: true, nextButton: true });
    }
  };

  return (
    <StyledLi
      id={section.Id}
      onClick={(event) => gcSectionHandler(section, event)}
      data-id={`${section.sec_id}`}
    >
      <FlexDiv onClick={() => sectionHandler(section)}>
        <BoldSpan>{section?.section_name}</BoldSpan>
        <img
          src={isExpanded ? UpArrow : DownArrow}
          alt="Toggle Arrow"
          onClick={toggleHandler}
          data-testid="toggleArrowImg"
        />
      </FlexDiv>
      {isExpanded && (
        <StyledUl data-testid="subsection-menu" id="subSectionMenu">
          {subSecData?.map((item) => (
            <StyledLi
              onClick={() => subsectionHandler(item)}
              key={item.Id}
              id={item.Id}
              data-id={`${item?.subsec_id}`}
              data-activeid={
                selectSubSectionId === item?.subsec_id ? "active" : ""
              }
            >
              <FlexDiv className="subMenuPadd">
                <StyledSubsection>{item.subsection_name}</StyledSubsection>
                {selectSubSectionId === item?.subsec_id ? (
                  <img src={RightActiveArrow} alt="active subsection" />
                ) : (
                  <></>
                )}
              </FlexDiv>
            </StyledLi>
          ))}
        </StyledUl>
      )}
    </StyledLi>
  );
};

export default SidebarMenuItem;
