import {createSlice} from '@reduxjs/toolkit';

const backToGridCodeSlice = createSlice({
    name: 'backToGridCode',
    initialState: {isBackToGridCode: false},
    reducers: {
        setBacktoGridCode: (state, action) => {
            state.isBackToGridCode = action.payload;
        }
    }
});

export const {setBacktoGridCode} = backToGridCodeSlice.actions;
export default backToGridCodeSlice.reducer;