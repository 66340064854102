import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../AppInsights";

export const fetchgcSectionData = async (key) => {
  appInsights.trackEvent({
    name: "API request",
    properties: {
      apiName: "Fetch GC section data",
      url: `api/GcMain/GetGcSec?section=${key}`,
    },
  });

  let data = [];
  let response;
  try {
    response = await fetch(
      `api/GcMain/GetGcSec?section=${encodeURIComponent(key)}`
    );
    data = await response.json();
  } catch (err) {
    appInsights.trackException({
      error: new Error(err),
      severityLevel: SeverityLevel.Error,
    });

    return { error: { name: err?.name, message: err?.message } };
  }

  if (data.error) {
    return { error: data.error };
  } else {
    return data;
  }
};
