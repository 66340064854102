import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewGlossary from "../Components/ViewGlossary";
import { fetchGlossaryItems } from "../store/viewglossary-actions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackError from "./FallbackError";
import { setPage } from "../store/viewGlossary-slice";

const ViewGlossaryPage = () => {
  const dispatch = useDispatch();

  const { data, error } = useSelector((state) => state.glossaryItems);

  useEffect(() => {
    if (error) {
      throw new Error(JSON.stringify(error));
    }
  }, [error]);

  useEffect(() => {
    dispatch(setPage('VIEW_GLOSSARY'));
    dispatch(fetchGlossaryItems());
  }, [dispatch]);

  const sentencesWithKey = data?.map((item) => {
    return {
      id: item?.Id,
      key: item?.Key,
      key_text: item?.key_text ? item?.key_text : item?.key,
      value: item?.value_text ? [item?.value_text] : [],
    };
  });
  return (
    <ErrorBoundary
      fallbackRender={FallbackError}
      onReset={(details) => {
        console.log(details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <ViewGlossary sentencesWithKey={sentencesWithKey} />
    </ErrorBoundary>
  );
};
export default ViewGlossaryPage;
