import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${({ primary }) => (primary ? "#B04A2A" : "#fff")};
  font-weight: 400;
  text-decoration: none;
`;

const NavLink = ({ children, to, primary, id, className }) => {
  return (
    <>
      <StyledLink primary={primary} to={to} id={id} className={className}>
        {children}
      </StyledLink>
    </>
  );
};

export default NavLink;
