import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LeftArrow from "../../Assets/leftArrowPagination.svg";
import LeftArrowDisabled from "../../Assets/leftArrowDisabled.svg";
import RightArrow from "../../Assets/rightArrowPagination.svg";
import RightArrowDisabled from "../../Assets/rightArrowDisabled.svg";
import LeftDoubleArrow from "../../Assets/leftDoubleArrow.svg";
import LeftDoubleArrowDisabled from "../../Assets/leftDoubleArrowDisabled.svg";
import RightDoubleArrow from "../../Assets/rightDoubleArrow.svg";
import RightDoubleArrowDisabled from "../../Assets/rightDoubleArrowDisabled.svg";
import { generateRandomNo } from "../../utils/utils";

const PaginationButton = styled.div`
  margin-top: 30px;
  padding: 5px 5px;
  min-width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-right: 0;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 400px;

  &: last-child {
    border-right: 1px solid #e0e0e0;
  }

  &.active {
    background-color: #e0e0e0;
    color: #161616;
  }

  &.paginationLeftArrow,
  &.paginationRightArrow,
  &.paginationLeftDoubleArrow,
  &.paginationRightDoubleArrow {
    padding: 5px 5px;
    & img {
      margin: auto;
      vertical-align: middle;
    }
    &.disabled {
      pointer-events: none;
    }
  }

  &.paginationRightArrow {
    border-left: 0;
  }
`;

const StyledFlex = styled.div`
  display: flex;
`;

const Pagination = ({ totalPages, onPageChange, testIdName, currentPage }) => {
  const [prevDots, setPrevDots] = useState(false);

  useEffect(() => {
    if (currentPage > 2 && totalPages > 4) {
      setPrevDots(true);
    } else {
      setPrevDots(false);
    }
  }, [currentPage, totalPages]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPages = Math.min(totalPages, 4);
    let startPage = 1;
    let endPage = maxPages;

    if (currentPage > 2) {
      if (currentPage === totalPages) {
        startPage = currentPage - 3;
        endPage = currentPage;
      } else if (currentPage === totalPages - 1) {
        startPage = currentPage - 2;
        endPage = currentPage + 1;
      } else {
        startPage = currentPage - 1;
        endPage = totalPages === currentPage ? currentPage : currentPage + 2;
      }
    }

    startPage = Math.max(startPage, 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={`pagination_${generateRandomNo()}`}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
          data-testid={`paginationNumButton_${i}`}
        >
          {i}
        </PaginationButton>
      );
    }

    if (totalPages > 4 && endPage < totalPages) {
      pageNumbers.push(<PaginationButton key="dots">...</PaginationButton>);
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  return (
    <StyledFlex className="pagination" id="pagination" data-testid={testIdName}>
      <PaginationButton
        className={`paginationLeftDoubleArrow ${
          currentPage > 2 ? "" : "disabled"
        }`}
        id="paginationLeftDoubleArrow"
        onClick={() => handlePageChange(1)}
        data-testid="fwdPrevButton"
      >
        <img
          src={currentPage > 2 ? LeftDoubleArrow : LeftDoubleArrowDisabled}
          alt="Left Double Arrow"
        />
      </PaginationButton>
      <PaginationButton
        className={`paginationLeftArrow ${currentPage > 1 ? "" : "disabled"}`}
        id="paginationLeftArrow"
        onClick={() => handlePageChange(currentPage - 1)}
        data-testid="prevButton"
      >
        <img
          src={currentPage > 1 ? LeftArrow : LeftArrowDisabled}
          alt="Left Arrow"
        />
      </PaginationButton>
      {prevDots && <PaginationButton key="dots">...</PaginationButton>}
      <StyledFlex>{renderPageNumbers()}</StyledFlex>
      <PaginationButton
        className={`paginationRightArrow ${
          currentPage < totalPages ? "" : "disabled"
        }`}
        id="paginationRightArrow"
        onClick={() => handlePageChange(currentPage + 1)}
        data-testid="nextButton"
      >
        <img
          src={currentPage < totalPages ? RightArrow : RightArrowDisabled}
          alt="Right Arrow"
        />
      </PaginationButton>
      <PaginationButton
        className={`paginationRightDoubleArrow ${
          currentPage < totalPages - 2 ? "" : "disabled"
        }`}
        id="paginationDoubleRightArrow"
        onClick={() => handlePageChange(totalPages)}
        data-testid="fwdNextButton"
      >
        <img
          src={
            currentPage < totalPages - 2
              ? RightDoubleArrow
              : RightDoubleArrowDisabled
          }
          alt="Right Double Arrow"
        />
      </PaginationButton>
    </StyledFlex>
  );
};

export default Pagination;
