import parse from "html-react-parser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Input from "../../Components/Input";
import AlphabeticalButtons from "../../Components/AlphabeticalButtons";
import GlossaryPopup from "../../Assets/glossaryPopup.svg";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGlossaryItems,
  fetchGlossaryItemsByAlphabets,
  fetchGlossaryItemsByKey,
} from "../../store/viewglossary-actions";
import { fetchAllGlossaryItemsAPI } from "../../store/viewGlossary-api";
import AutofillDropdown from "../../Components/AutofillDropdown/AutofillDropdown";
import ParsedHtml from "../../utils/ParsedHtml";
import NoResultsFound from "../../Components/NoResultsFound";
import Loader from "../../Components/Loader";
import { generateRandomNo } from "../../utils/utils";
import { toggleAlphaBtn } from "../../store/viewGlossary-slice";
import TopArrowIcon from "../../Assets/topArrow.svg";
import BottomArrowIcon from "../../Assets/bottomArrow.svg";

const StyledRightBlock = styled.div`
  max-width: 353px;
  margin: 0 0 21px 0;
  width: 100%;
  position: fixed;
  right: 0;
`;

const PaddingDiv = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 17px 0 14px 0;
  width: 99%;
`;

const StyledGlossaryTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
`;

const StyledGlossaryIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPara = styled.div`
  font-size: 14px;

  & p {
    text-align: left;
  }
`;

const StyledHeading = styled.span`
  font-size: 16px;
`;

const StyledGlossaryResultSeparator = styled.div`
  border-top: 1px solid #8d8d8d;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 21px;
`;

const StyledResultContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  margin-top: 17px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ececec;
    background-color: #ececec;
  }

  &::-webkit-scrollbar {
    width: 11px;
    background-color: #a8a8a8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
`;

const StyledFlexForArrowBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const StyledArrowBtnContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
`;

const StyledPaddingDiv = styled.div`
  padding-bottom: 200px;
`;

const GlossarySideBar = () => {
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [currentAlphabet, setCurrentAlphabet] = useState("");
  const [isEmptyAlphabets, setIsEmptyAlphabets] = useState(null);
  const [glossaryItems, setGlossaryItems] = useState([]);
  const [autofillItems, setAutofillItems] = useState([]);
  const [autofillVisible, setAutofillVisible] = useState(false);
  const [trigger, setTrigger] = useState(null);

  const inputRef = useRef(null);
  const goTopref = useRef(null);

  const dispatch = useDispatch();

  const { data, loading, error, isAlphaBtnHidden } = useSelector((state) => ({
    data: state?.glossaryItems?.data,
    loading: state?.glossaryItems?.loading,
    error: state?.glossaryItems?.error,
    isAlphaBtnHidden: state?.glossaryItems?.isAlphaBtnHidden,
  }));

  useEffect(() => {
    if (currentAlphabet === "All") {
      dispatch(fetchGlossaryItems());
    } else if (currentAlphabet !== "") {
      dispatch(fetchGlossaryItemsByAlphabets(currentAlphabet));
    }
  }, [currentAlphabet, dispatch]);

  useEffect(() => {
    let timeoutID;
    if (searchBoxValue?.trim()?.length >= 3 && glossaryItems?.length > 0) {
      timeoutID = setTimeout(() => {
        let partialMatches = [],
          exactMatches = [],
          endMatches = [];

        glossaryItems.forEach((str) => {
          if (
            str?.trim()?.toLowerCase() === searchBoxValue?.trim()?.toLowerCase()
          ) {
            exactMatches.push(str);
          } else if (
            str?.toLowerCase()?.endsWith(searchBoxValue?.trim()?.toLowerCase())
          ) {
            endMatches.push(str);
          } else if (
            str?.toLowerCase()?.includes(searchBoxValue?.trim()?.toLowerCase())
          ) {
            partialMatches.push(str);
          }
        });

        partialMatches.sort((a, b) => {
          const indexA = a
            ?.toLowerCase()
            ?.indexOf(searchBoxValue?.trim()?.toLowerCase());
          const indexB = b
            ?.toLowerCase()
            ?.indexOf(searchBoxValue?.trim()?.toLowerCase());

          return indexA - indexB;
        });

        const items = [...exactMatches, ...partialMatches, ...endMatches].slice(
          0,
          5
        );

        if (items?.length > 0) {
          setAutofillVisible(true);
        } else {
          setAutofillVisible(false);
        }

        setAutofillItems(items);
      }, 500);
    } else {
      setAutofillVisible(false);
      setAutofillItems([]);
    }
    return () => clearTimeout(timeoutID);
  }, [searchBoxValue, glossaryItems]);

  useEffect(() => {
    setIsEmptyAlphabets(data?.length === 0);
  }, [data]);

  useEffect(() => {
    setIsEmptyAlphabets(null);
    fetchAllGlossaryItemsAPI().then((data) => {
      if (Array.isArray(data)) {
        setGlossaryItems(data?.map((obj) => obj?.Key));
      }
    });
  }, []);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  useEffect(() => {
    if (trigger !== null) {
      const timeoutID = setTimeout(() => {
        setAutofillVisible(false);
      }, 520);

      return () => clearTimeout(timeoutID);
    }
  }, [trigger]);

  const searchHandler = (key) => {
    if (key !== "") {
      dispatch(fetchGlossaryItemsByKey(key));
      setCurrentAlphabet("");
    }
  };

  const alphabetsFilterHandler = (alphabet) => {
    setCurrentAlphabet(alphabet);
    setSearchBoxValue("");
    if (goTopref.current) {
      goTopref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (error) {
      throw new Error(JSON.stringify(error));
    }
  }, [error]);

  const itemsForCurrentPage = Array.isArray(data)
    ? data?.map((item) => {
        return {
          id: item?.Id,
          key: item?.Key,
          Key_text: item?.key_text ? item?.key_text : item?.Key,
          value: item?.value_text ? [item?.value_text] : [],
        };
      })
    : [];

  return (
    <StyledRightBlock className="rightBlock" id="rightBlock">
      <PaddingDiv>
        <StyledIconContainer>
          <StyledGlossaryTitle className="glossaryTitle" id="glossaryTitle">
            Glossary
          </StyledGlossaryTitle>
          <Link to="/viewGlossary">
            <StyledGlossaryIcon>
              <img src={GlossaryPopup} alt="View Glossary" />
            </StyledGlossaryIcon>
          </Link>
        </StyledIconContainer>
        <div className="position-relative" id="InputDiv">
          <Input
            value={searchBoxValue}
            onChange={(event) => {
              setSearchBoxValue(event.target.value);
            }}
            type={"search"}
            isGlossary={true}
            placeholder="Search from Glossary"
            onClickSearch={() => {
              searchHandler(searchBoxValue?.trim());
            }}
            onEnter={() => {
              if (searchBoxValue?.trim() !== "") {
                searchHandler(searchBoxValue?.trim());
                setAutofillVisible(false);
                inputRef.current.blur();
              }
            }}
            ref={inputRef}
            id="gcGlossarySearchInput"
            data-testid="gcGlossarySearchInput"
          />
          <AutofillDropdown
            autofillItems={autofillItems}
            onItemClick={(autofillItem) => {
              setSearchBoxValue(autofillItem);
              setTrigger((trigger) => !trigger);
              inputRef.current.focus();
              searchHandler(autofillItem);
            }}
            searchText={searchBoxValue?.trim()}
            visible={autofillVisible}
            setVisible={setAutofillVisible}
          />
        </div>
      </PaddingDiv>
      <StyledResultContainer>
        <PaddingDiv>
          {!isAlphaBtnHidden ? (
            <AlphabeticalButtons
              currentAlphabet={currentAlphabet}
              alphabeticalHandler={alphabetsFilterHandler}
              buttonsParentClassName="glossaryRightButtons"
            />
          ) : (
            <></>
          )}
          <StyledGlossaryResultSeparator />
          <StyledFlexForArrowBtn>
            <StyledArrowBtnContainer
              data-testid={!isAlphaBtnHidden ? "topArrowBtn" : "bottomArrowBtn"}
              onClick={() => {
                dispatch(toggleAlphaBtn());
              }}
            >
              <img
                alt={!isAlphaBtnHidden ? "up" : "down"}
                src={!isAlphaBtnHidden ? BottomArrowIcon : TopArrowIcon}
              />
            </StyledArrowBtnContainer>
          </StyledFlexForArrowBtn>
          {isEmptyAlphabets === true ? (
            <NoResultsFound />
          ) : (
            <>
              {isEmptyAlphabets === false && !loading ? (
                <div ref={goTopref}>
                  <StyledPaddingDiv>
                    {itemsForCurrentPage?.map(
                      ({ id, key, Key_text, value }) => (
                        <div
                          key={`${key}_${id}`}
                          data-testid="test_glossary_item"
                        >
                          <StyledHeading>
                            {Key_text && parse(Key_text)}
                          </StyledHeading>
                          <StyledPara>
                            {value?.map((sentence) => (
                              <React.Fragment
                                key={`${sentence?.substring(
                                  0,
                                  20
                                )}_${generateRandomNo()}`}
                              >
                                {sentence && (
                                  <ParsedHtml
                                    value_text={`<p>${sentence
                                      ?.replace(/\\"/g, '"')
                                      ?.replace(/\\\\/g, "\\")}</p>`}
                                    section={"GD"}
                                    subSec={"GD.1"}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          </StyledPara>
                        </div>
                      )
                    )}
                  </StyledPaddingDiv>
                </div>
              ) : (
                <>
                  {isEmptyAlphabets !== null && (
                    <div className="position-relative">
                      <Loader
                        className="rightSideglossaryLoader"
                        id="rightSideglossaryLoader"
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </PaddingDiv>
      </StyledResultContainer>
    </StyledRightBlock>
  );
};

export default GlossarySideBar;
