import { createSlice } from "@reduxjs/toolkit";

const gcSectionSlice = createSlice({
  name: "gcSection",
  initialState: {
    gcSectionArr: [],
    gcSectionLoading: false,
    gcSectionError: null,
    gcUpdateDate: "",
  },
  reducers: {
    setgcSectionItems: (state, action) => {
      state.gcSectionArr = action.payload;
      state.gcSectionLoading = false;
      state.gcSectionError = null;
    },
    setgcSectionLoading: (state) => {
      state.gcSectionLoading = true;
    },
    setgcSectionError: (state, action) => {
      state.gcSectionLoading = false;
      state.gcSectionError = action.payload;
    },
    setgcUpdateDate: (state, action) => {
      state.gcUpdateDate = action.payload;
    },
  },
});

export const {
  setgcSectionItems,
  setgcSectionLoading,
  setgcSectionError,
  setgcUpdateDate,
} = gcSectionSlice.actions;
export default gcSectionSlice.reducer;
