import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { fetchTooltipExplainationData } from "../../store/Tooltip/api";
import ParsedHtml from "../../utils/ParsedHtml";
import Button from "../Button";

const StyledOverflow = styled.div`
  overflow-y: ${({ isShowMore, tooltipMoreHeight }) =>
    isShowMore && tooltipMoreHeight > 300 ? "scroll" : "inherit"};
  overflow-x: hidden;
  max-height: ${({ tooltipMoreHeight }) =>
    tooltipMoreHeight > 300 ? "260px" : "auto"};
  padding-right: ${({ isShowMore }) => (isShowMore ? "20px" : "10px")};

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #525252;
  }

  &::-webkit-scrollbar {
    width: 11px;
    background-color: #525252;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

const TooltipDiv = styled.div`
  position: absolute;
  background-color: #393939;
  color: #fff;
  padding: 20px 10px 20px 20px;
  z-index: 999;
  width: ${({ isShowMore }) => (isShowMore ? "548px" : "256px")};
  transform: translateX(-50%);
  left: ${({ left }) => left};
  top: 30px;
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    padding: 8px;
  }

  &.tooltipOnTopLeftEdges {
    left: 0;
    top: 30px;
    bottom: inherit;
    right: inherit;
    transform: none;
  }

  &.tooltipRight {
    right: 0;
    bottom: inherit;
    top: 33px;
    left: inherit;
    transform: none;
  }

  &.tooltipBottom {
    left: 0;
    bottom: ${({ height }) => `${height + 1.5}px`};
    top: inherit;
    right: inherit;
    transform: none;

    &::before {
      content: "";
      position: absolute;
      top: inherit;
      left: 0;
      width: 100%;
      bottom: -11px;
      padding: 8px;
    }
  }

  &.tooltipBottomLeft {
    left: ${({ left }) => left};
    bottom: 0;
    top: inherit;
    right: inherit;
  }

  &.tooltipBottomRight {
    right: 0;
    bottom: ${({ height }) => `${height + 1.5}px`};
    top: inherit;
    left: inherit;
    transform: none;
  }
`;

const TooltipArrow = styled.div`
  position: absolute;
  content: "";
  border: solid transparent;
  border-width: 15px;
  z-index: 1;
  ${({ direction, height }) => {
    switch (direction) {
      case "top":
        return css`
          border-bottom-color: #393939;
          top: -23px;
          left: 50%;
          transform: translateX(-50%) translateY(100%);
        `;
      case "right":
        return css`
          border-left-color: #393939;
          top: 50%;
          left: calc(100% + 20px);
          transform: translateY(-50%);
        `;
      case "bottom":
        return css`
          border-top-color: #393939;
          left: 45%;
          top: calc(100% - (${height - 23.25}px));
          transform: translateX(-50%) translateY(-100%);
        `;
      case "left":
        return css`
          border-right-color: #393939;
          top: 50%;
          left: calc(100% - 5px);
          transform: translateY(-50%);
        `;
      default:
        return "";
    }
  }}
`;

const TootipRelative = styled.div`
  position: relative;
  display: inline;
`;

const ButtonParent = styled.span`
  display: flex;
  justify-content: right;
`;

const AlignPara = styled.p`
  text-align: left;
`;

const StrongTextColor = styled.strong`
  color: #000;
  transition: 0.3s;
`;

const TooltipButton = styled(Button)`
  padding: 8px 15px;
  border: none;
`;

const Tooltip = ({
  data,
  uniqueId,
  setActiveTooltip,
  showTooltip,
  secId,
  setCurrentSecIdForTooltip,
}) => {
  const [tooltipExplainationText, setTooltipExplainationText] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [tooltipHeight, setTooltipHeight] = useState(null);

  const tooltipRef = useRef(null);
  const tooltipDetailRef = useRef(null);
  const abortController = new AbortController();

  const {
    left,
    bottom,
    top,
    tooltipClassName,
    right,
    tooltipArrow,
    parentRight,
    height,
  } = useMemo(() => {
    if (showTooltip && tooltipRef?.current) {
      const parentElem = document.querySelector("#middleBlock");
      const {
        left: parentLeft,
        top: parentTop,
        bottom: parentBottom,
        right: parentRight,
      } = parentElem.getBoundingClientRect();

      let { width, height, left, top, bottom, right, y } =
        tooltipRef.current.getBoundingClientRect();

      left = left - parentLeft;
      top = top - parentTop;
      bottom = parentBottom - bottom;
      right = parentRight - right;
      if (left < 260 && top > 80 && y > 100 && y < 600) {
        // Left Top Edges
        return {
          parentRight,
          height,
          left: `${left}px`,
          top: "inherit",
          bottom: "inherit",
          tooltipClassName: "tooltipOnTopLeftEdges",
          tooltipArrow: "top",
        };
      } else if (right < 250 && top > 100 && y < 600) {
        //only top right
        return {
          parentRight,
          height,
          top: "inherit",
          bottom: "inherit",
          left: "inherit",
          right: `${right}px`,
          tooltipClassName: "tooltipRight",
          tooltipArrow: "top",
        };
      } else if (right > 10 && y > 600 && left > 500) {
        // Right  Bottom
        return {
          parentRight,
          height,
          top: "inherit",
          bottom: `${bottom}px`,
          right: 0,
          tooltipClassName: "tooltipBottomRight",
          tooltipArrow: "bottom",
        };
      } else if (y > 600) {
        // Left Bottom Edge
        return {
          parentRight,
          height,
          left: "inherit",
          top: "inherit",
          bottom: 0,
          right: 0,
          tooltipClassName: "tooltipBottom",
          tooltipArrow: "bottom",
        };
      } else {
        return {
          parentRight,
          height,
          top: `${height + 15}px`,
          left: `${width / 2}px`,
          bottom: "inherit",
          tooltipClassName: "",
          tooltipArrow: "top",
        };
      }
    } else {
      return {};
    }
  }, [showTooltip]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer;

    if (showTooltip) {
      timer = setTimeout(() => {
        fetchTooltipExplainationData(data, abortController.signal)
          .then((data) => {
            setTooltipExplainationText(data);
          })
          .catch(() => {});
      }, 500);
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
      setShowMore(false);
      setTooltipHeight(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  useEffect(() => {
    if (
      showTooltip &&
      tooltipExplainationText?.[0]?.value_text &&
      tooltipDetailRef?.current &&
      height > 25
    ) {
      tooltipDetailRef.current.style.left = "0";
      const { right: detailRight } =
        tooltipDetailRef?.current?.getBoundingClientRect();

      if (detailRight > parentRight) {
        tooltipDetailRef.current.style.left = `${
          parentRight - (detailRight + 16)
        }px`;
      } else {
        tooltipDetailRef.current.style.left = "-40px";
      }
    }
  }, [showTooltip, tooltipExplainationText, parentRight, showMore, height]);

  const onMouseEnterHandler = () => {
    setCurrentSecIdForTooltip(secId);
    setActiveTooltip(uniqueId);
  };

  const onMouseLeaveHandler = () => {
    setActiveTooltip("");
    setShowMore(false);
    setTooltipHeight(null);
  };

  const moreBtnHandler = () => {
    setShowMore(true);
    setTimeout(() => {
      const tooltipElement = document.getElementById("tooltip");
      if (tooltipElement) {
        const offsetHeight = tooltipElement.offsetHeight;
        setTooltipHeight(offsetHeight);
      }
    });
  };

  return (
    <TootipRelative
      ref={tooltipRef}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      data-testid="tooltip-element"
    >
      <StrongTextColor className="cursorPointer">{data}</StrongTextColor>
      {showTooltip && tooltipExplainationText?.[0]?.value_text && (
        <>
          <TooltipDiv
            ref={tooltipDetailRef}
            left={left}
            bottom={bottom}
            top={top}
            right={right}
            className={tooltipClassName}
            isShowMore={showMore}
            height={height}
            id="tooltip"
          >
            <StyledOverflow
              isShowMore={showMore}
              tooltipMoreHeight={tooltipHeight}
            >
              {showMore ? (
                <ParsedHtml
                  value_text={`<p className="m-0">${(tooltipExplainationText ||
                    [])[0]?.value_text
                    ?.replace(/\\"/g, '"')
                    ?.replace(/\\\\/g, "\\")}</p>`}
                  section={"GD"}
                  subSec={"GD.1"}
                />
              ) : (
                <AlignPara className="m-0">
                  {`${(tooltipExplainationText || [])[0]?.content?.slice(
                    0,
                    120
                  )} ${
                    tooltipExplainationText[0]?.content?.length > 120
                      ? `...`
                      : ""
                  }`}
                </AlignPara>
              )}
              {!showMore &&
              tooltipExplainationText[0]?.content?.length > 120 ? (
                <ButtonParent>
                  <TooltipButton
                    primary
                    onClick={moreBtnHandler}
                    data-testid="moreButton"
                  >
                    More
                  </TooltipButton>
                </ButtonParent>
              ) : (
                <></>
              )}
            </StyledOverflow>
          </TooltipDiv>
          <TooltipArrow direction={tooltipArrow} height={height} />
        </>
      )}
    </TootipRelative>
  );
};

export default Tooltip;
