import React from "react";
import styled from "styled-components";
import Link from "../Link/index";

const StyledUl = styled.ul`
  display: flex;
  margin: 0;
  justify-content: center;
`;

const StyledLi = styled.li`
  list-style: none;
  padding: 16px;
  transition: all 0.3s ease;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Nav = () => {
  const BASE_URL = process.env.REACT_APP_ENVIRONMENT_BASE_URL;

  const mainMenus = [
    {
      name: "What We Do",
      link: `${BASE_URL}/what-we-do`,
      className: "whatWeDo",
      id: "whatWeDo",
    },
    {
      name: "Electricity Explained",
      link: `${BASE_URL}/electricity-explained`,
      className: "electricityExplained",
      id: "electricityExplained",
    },
    {
      name: "Future Energy",
      link: `${BASE_URL}/future-energy`,
      className: "futureEnergy",
      id: "futureEnergy",
    },
    {
      name: "Industry Information",
      link: `${BASE_URL}/industry-information`,
      className: "industryInformation",
      id: "industryInformation",
    },
    {
      name: "News and Events",
      link: `${BASE_URL}/news-and-events`,
      className: "newsAndEvents",
      id: "newsAndEvents",
    },
    {
      name: "Research and Publications",
      link: `${BASE_URL}/research-and-publications`,
      className: "researchAndPublications",
      id: "researchAndPublications",
    },
  ];

  const megaMenuHandler = (menuId) => {
    console.log("menuid", menuId);
  }

  return (
    <>
      <StyledUl
        data-testid="nav"
        role="navigation"
        className="navLinks"
        id="navLinks"
      >
        {mainMenus?.map((menu) => (
          <StyledLi key={menu.name} className={menu.className} id={menu.id} onClick={() => megaMenuHandler(menu.id)}>
            <Link to={menu.link}>{menu.name}</Link>
          </StyledLi>
        ))}
      </StyledUl>
    </>
  );
};
export default Nav;
