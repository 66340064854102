import React from "react";
import styled from "styled-components";

const AccordionContainer = styled.div`
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
`;

const Accordion = ({ children, activeAccordion, setActiveAccordion }) => {
  const toggleItem = (e, index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <AccordionContainer className="accordionContainer" id="accordionContainer">
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          isopen: activeAccordion === index,
          onClick: (e) => toggleItem(e, index),
        })
      )}
    </AccordionContainer>
  );
};

export default Accordion;
