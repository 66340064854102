import { createSlice } from "@reduxjs/toolkit";

const getGCKeySlice = createSlice({
  name: "getGCkey",
  initialState: {
    getGCkeyArr: null,
    getGCkeyLoading: false,
    getGCkeyError: null,
  },
  reducers: {
    setgetGCkeyItems: (state, action) => {
      state.getGCkeyArr = action.payload;
      state.getGCkeyLoading = false;
      state.getGCkeyError = null;
    },
    setgetGCkeyLoading: (state) => {
      state.getGCkeyLoading = true;
    },
    setgetGCkeyError: (state, action) => {
      state.getGCkeyLoading = false;
      state.getGCkeyError = action.payload;
    },
  },
});

export const { setgetGCkeyItems, setgetGCkeyLoading, setgetGCkeyError } =
  getGCKeySlice.actions;
export default getGCKeySlice.reducer;
