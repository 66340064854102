import styled from "styled-components";
import ParsedHtml from "../../utils/ParsedHtml";
import OrderListIcon from "../../Assets/orderListIcon.svg";
import { useEffect, useRef, useState } from "react";
import { escapeRegExp, textSearchHandler } from "../../utils/utils";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FooterButton = styled.span`
  color: #b04a2a;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #9e4326;
  }
`;

const SearchResultDiv = styled.div`
  background-color: #fff;
  border: 1px solid #b04a2a;
  padding: 18px;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 15px;
`;

const OrderListIndexDiv = styled.div`
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const OrderIndexTextSpan = styled.div`
  color: #b04a2a;
  margin-left: 5px;
  font-weight: bolder;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #9e4326;
  }
`;

const ValueText = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: ${({ isexpanded }) => (isexpanded ? `500px` : `194px`)};
  overflow-y: clip;
`;

const ListIconSpan = styled.span`
  margin-right: 3px;
`;

const RecordsIconMarg = styled.img`
  margin-top: 1px;
  display: flex;
`;

const SearchResultCard = ({
  item,
  index,
  searchedArr,
  currentPage,
  itemsPerPage,
  contentSearchedValue,
  searchedResultsHandler,
}) => {
  const [shouldShowMoreLessBtn, setShouldShowMoreLessBtn] = useState(false);
  const [shouldShowContentLink, setShouldShowContentLink] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  useEffect(() => {
    if (ref?.current?.scrollHeight > ref?.current?.clientHeight) {
      setShouldShowMoreLessBtn(true);
    }
  }, []);

  useEffect(() => {
    if (ref?.current?.scrollHeight > ref?.current?.clientHeight) {
      if (isExpanded) {
        setShouldShowContentLink(true);
      } else {
        setShouldShowContentLink(false);
      }
    }
  }, [isExpanded]);

  const highlightKeyword = (text) => {
    const regex = new RegExp(
      `\\b(${escapeRegExp(contentSearchedValue)})\\b`,
      "gi"
    );
    const parts = text.split(regex);
    return parts.map((part) => (
      <span
        key={part}
        style={{
          backgroundColor:
            part.toLowerCase() === contentSearchedValue.toLowerCase()
              ? "#c0e2bd"
              : "transparent",
        }}
      >
        {part}
      </span>
    ));
  };

  return (
    <SearchResultDiv className="searchResultcard" id="searchResultcard">
      <h4 className="m-0 subsectionTitle" id="subsectionTitle">
        {highlightKeyword(item?.subsection)}
      </h4>
      <ValueText ref={ref} isexpanded={+!!isExpanded}>
        <ParsedHtml
          value_text={textSearchHandler(item.value_text, contentSearchedValue)
            ?.replace(/\\"/g, '"')
            ?.replace(/\\\\/g, "\\")}
          section={item?.section}
          subSec={item?.subsection}
        />
      </ValueText>
      <OrderListIndexDiv
        onClick={(event) => searchedResultsHandler(item, event)}
      >
        <ListIconSpan>
          <RecordsIconMarg
            src={OrderListIcon}
            alt="Order List"
            className="orderListIcon"
            id="orderListIcon"
          />
        </ListIconSpan>
        <OrderIndexTextSpan>
          {(currentPage - 1) * itemsPerPage + index + 1}/{searchedArr?.length}
        </OrderIndexTextSpan>
      </OrderListIndexDiv>
      {shouldShowMoreLessBtn ? (
        <Footer>
          <FooterButton
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((isExpanded) => !isExpanded);
            }}
          >
            ...Read {isExpanded ? "Less" : "More"}
          </FooterButton>
          {shouldShowContentLink ? (
            <FooterButton
              onClick={(event) => searchedResultsHandler(item, event)}
            >
              ... {item?.subsection}
            </FooterButton>
          ) : (
            <></>
          )}
        </Footer>
      ) : (
        <></>
      )}
    </SearchResultDiv>
  );
};

export default SearchResultCard;
