import styled from "styled-components";

const ErrorSection = styled.section`
  width: 100%;
  display: flex;
  height: 50vh;
  justify-content: center;
`;

const ErrorContent = styled.div`
  margin: auto;
  width: 90%;
  text-align: center;
`;

const ErrorHeading = styled.h4`
  font-size: 16px;
  font-weight: bold;
`;

const ErrorPara = styled.p`
  font-size: 16px;
`;

function FallbackError({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <ErrorSection>
      <ErrorContent>
        <header>
          <ErrorHeading>Temporarily unavailable</ErrorHeading>
        </header>
        <div>
          <ErrorPara>
            The website that you’re trying to reach is having technical
            difficulties and is currently unavailable.
            <br /> We are aware of the issue and are working hard to fix it.
            Thank you for your patience.
          </ErrorPara>
        </div>
      </ErrorContent>
    </ErrorSection>
  );
}

export default FallbackError;
