import React, { useState } from "react";
import styled from "styled-components";
import SidebarMenuItem from "./SidebarMenuItem";
import { useSelector } from "react-redux";
import Button from "../../Components/Button";

const StyledSidebar = styled.div``;

const StyledLeftSideMenu = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  max-height: 70vh;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ececec;
    background-color: #ececec;
  }
  &::-webkit-scrollbar {
    width: 11px;
    background-color: #a8a8a8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  color: #b04a2a;
  padding-left: 27px;
  padding-right: 10px;
  padding-bottom: 120px;
`;

const StyledLi = styled.li`
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const ButtonsParentDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-right: 17px;
`;

const Sidebar = ({
  setPrevAndNextBtnHide,
  prevAndNextBtnHide,
  mainMenuClickHandler,
  isMainMenuVisible,
}) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);

  const { sideMenuData } = useSelector((state) => ({
    sideMenuData: state?.sideMenuData?.sideMenuData,
  }));

  const levelTwo = Array.isArray(sideMenuData)
    ? sideMenuData?.filter((menu) => menu.sec_ind === "Y")
    : [];

  return (
    <StyledSidebar>
      <ButtonsParentDiv>
        <Button
          primary
          className="flex align-items"
          onClick={() => setIsAllExpanded(true)}
          dataTestId="expend-all"
          id="expend-all"
        >
          Expand All
        </Button>
        <Button
          primary
          className="flex align-items"
          onClick={() => setIsAllCollapsed(true)}
          dataTestId="collapse-all"
          id="collapse-all"
        >
          Collapse All
        </Button>
      </ButtonsParentDiv>
      <StyledLeftSideMenu>
        <StyledUl>
          <StyledLi
            onClick={mainMenuClickHandler}
            data-testid="gridcode-menu"
            className="cursorPointer"
            id="theCompleteGridCodeMenu"
          >
            <FlexDiv>
              <BoldSpan>The Complete Grid Code </BoldSpan>
            </FlexDiv>
          </StyledLi>
          {isMainMenuVisible && (
            <StyledUl data-testid="section-menu">
              {levelTwo?.map((section) => (
                <SidebarMenuItem
                  key={section.Id}
                  section={section}
                  prevAndNextBtnHide={prevAndNextBtnHide}
                  setPrevAndNextBtnHide={setPrevAndNextBtnHide}
                  isAllCollapsed={isAllCollapsed}
                  isAllExpanded={isAllExpanded}
                  setIsAllCollapsed={setIsAllCollapsed}
                  setIsAllExpanded={setIsAllExpanded}
                />
              ))}
            </StyledUl>
          )}
        </StyledUl>
      </StyledLeftSideMenu>
    </StyledSidebar>
  );
};

export default Sidebar;
