import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setBacktoGridCode } from "../../store/backToGridCode-slice";
import {
  fetchGlossaryItems,
  fetchGlossaryItemsByAlphabets,
} from "../../store/viewglossary-actions";
import Accordion from "../Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import AlphabeticalButtons from "../AlphabeticalButtons";
import Banner from "../Banner";
import Pagination from "../Pagination";
import NoResultsFound from "../NoResultsFound";
import ParsedHtml from "../../utils/ParsedHtml";
import { generateRandomNo } from "../../utils/utils";
import { setPage } from "../../store/viewGlossary-slice";

const StyledHeading = styled.h1`
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 80px;
  font-size: 26px;
  font-weight: 400;
`;

const StyledPaginationRight = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: right;
`;
const StyledBannerContainer = styled.div`
  margin-top: 151px;
`;
const StyledBredcrumb = styled.div`
  padding: 0 30px;
`;

const ViewGlossary = ({ sentencesWithKey }) => {
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const [itemsForCurrentPage, setItemsForCurrentPage] = useState([]);
  const [isEmptyAlphabets, setIsEmptyAlphabets] = useState(false);
  const [currentAlphabet, setCurrentAlphabet] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const paginationHandlePageChange = (page) => {
    setActiveAccordion(null);
    const alphabeticalOrderByPagination = [];
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    setCurrentPage(page);

    setItemsForCurrentPage(
      alphabeticalOrderByPagination?.length === 0
        ? sentencesWithKey?.slice(startIndex, endIndex)
        : alphabeticalOrderByPagination?.slice(startIndex, endIndex)
    );
    window.scrollTo({ top: 500, behavior: "smooth" });
  };

  useEffect(() => {
    setItemsForCurrentPage(sentencesWithKey?.slice(0, 10));
    setIsEmptyAlphabets(sentencesWithKey?.length === 0);
    setCurrentPage(1);
  }, [sentencesWithKey]);

  useEffect(() => {
    if (currentAlphabet === "All") {
      dispatch(fetchGlossaryItems());
    } else if (currentAlphabet !== "") {
      dispatch(fetchGlossaryItemsByAlphabets(currentAlphabet));
    }
  }, [currentAlphabet, dispatch]);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  useEffect(() => {
    dispatch(setPage("GRID_CODE"));
  }, [dispatch]);

  const alphabetsFilterHandler = (alphabet) => {
    setActiveAccordion(null);
    setCurrentAlphabet(alphabet);
  };

  const backToGridCodehandler = () => {
    dispatch(setBacktoGridCode(true));
  };

  return (
    <>
      <StyledBannerContainer>
        <Banner backToGridCodehandler={backToGridCodehandler} />
      </StyledBannerContainer>

      <StyledHeading className="glossaryHeading" id="glossaryHeading">
        Glossary
      </StyledHeading>
      <div style={{ marginTop: "20px" }}>
        <AlphabeticalButtons
          currentAlphabet={currentAlphabet || "All"}
          alphabeticalHandler={alphabetsFilterHandler}
        />
        {isEmptyAlphabets ? (
          <StyledBredcrumb>
            <NoResultsFound />
          </StyledBredcrumb>
        ) : (
          <Accordion
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          >
            {itemsForCurrentPage?.map(({ key, key_text, value, id }) => (
              <AccordionItem
                title={key_text}
                key={`${key}_${id}`}
                role="Accordion Item"
              >
                <>
                  {value?.map((sentence) => (
                    <React.Fragment
                      key={`${sentence?.substring(
                        0,
                        20
                      )}_${generateRandomNo()}`}
                    >
                      {sentence ? (
                        <ParsedHtml
                          value_text={`<p>${sentence
                            ?.replace(/\\"/g, '"')
                            ?.replace(/\\\\/g, "\\")}</p>`}
                          section={"GD"}
                          subSec={"GD.1"}
                        />
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </div>
      <StyledPaginationRight>
        {sentencesWithKey?.length >= 10 && (
          <Pagination
            totalPages={Math.ceil(sentencesWithKey?.length / itemsPerPage)}
            onPageChange={paginationHandlePageChange}
            currentPage={currentPage}
          />
        )}
      </StyledPaginationRight>
    </>
  );
};

export default ViewGlossary;
