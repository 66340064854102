import React, { useState, useEffect } from "react";
import BackToTopButton from "../../Assets/backToTopButton.svg";
import styled from "styled-components";

const ScrollTopBtn = styled.button`
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 92px;
  right: 33px;
  background-color: #b04a2a;
  color: rgb(255, 255, 255);
  border: 1px solid #b04a2a;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <ScrollTopBtn
          onClick={scrollToTop}
          role="button"
          data-testid="scroll-to-top-button"
          className="scrollTopBtn"
          id="scrollTopBtn"
        >
          <img src={BackToTopButton} alt="back to top" id="backToTop" />
        </ScrollTopBtn>
      )}
    </>
  );
};

export default ScrollToTopButton;
