import React, { useEffect } from "react";
import Gridcode from "../gridCodeView";
import { setPage } from "../store/viewGlossary-slice";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('GRID_CODE'));
  }, [dispatch]);

  return <Gridcode />;
};

export default Home;
