import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import arrowLeft from "../Assets/arrow-left.svg";
import arrowRight from "../Assets/arrow-right.svg";
import ClockIcon from "../Assets/clockIcon.svg";
import NextArrowWhite from "../Assets/nextArrowWhite.svg";
import PrevArrowWhite from "../Assets/prevArrowWhite.svg";
import DownloadIconSmall from "../Assets/downloadIcon.svg";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import BreadcrumbDropdown from "../Components/Breadcrumb/BreadcrumbDropdown";
import Button from "../Components/Button";
import Input from "../Components/Input";
import ParsedHtmlWithTooltip from "../Components/ParsedHtmlWithTooltip";
import Tabs from "../Components/Tabs";
import { BreadcrumbDropdownlinks, breadcrumbItems } from "../GLOBALDATA";
import { setBacktoGridCode } from "../store/backToGridCode-slice";
import { fetchgcSectionMenusData } from "../store/gcSection/actions";
import { fetchgcSubSectionMenuData } from "../store/gcSubSection/actions";
import { fetchGetGCKeyDataActions } from "../store/getGCKey/actions";
import {
  setIsSearchedResult,
  setIsSectionOrSubSection,
  setOnContentSearchSectionActive,
  setSearchedContent,
  setSelectedSectionId,
  setSelectedSubSectionId,
  setSelectedSubsection,
} from "../store/selectedSubsection-slice";
import { fetchGridcodeSidebarData } from "../store/sidebar/actions";
import { setActivetab } from "../store/tabs-slice";
import { textSearchHandler } from "../utils/utils";
import BodyMiddleTextComponent from "./Atoms/BodyMiddleText";
import GlossarySideBar from "./Atoms/GlossarySideBar";
import Sidebar from "./Atoms/Sidebar";

const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: -30px;
  margin-top: 105px;
  padding-bottom: 344px;
`;

const StyledLeftSection = styled.div`
  max-width: 353px;
  width: 100%;
  position: fixed;
  background-color: #fff;
`;

const StyledMiddleBlock = styled.div`
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  flex: 1;
  width: calc(100% - 706px);
  overflow: hidden;
  margin-left: ${({ isGridCodeView }) => (isGridCodeView ? "353px" : "0")};
  margin-right: ${({ isGlossaryView }) => (isGlossaryView ? "353px" : "0")};
  min-height: 600px;

  &.grayBg {
    background-color: #f4f4f4;
  }
`;

const StyledSearchBoxErr = styled.div`
  font-size: 14px;
  color: #cf0c0c;
  padding-top: 10px;
  letter-spacing: 0.32px;
`;

const StyledTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddingDiv = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const PaddFifteen = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const DateSpan = styled.span`
  color: #525252;
  font-size: 14px;
  margin-left: 5px;
`;

const SideMenuInputDiv = styled(PaddingDiv)`
  margin-top: 20px;
`;

const ButtonsParentDiv = styled.div`
  display: flex;
  justify-content: right;
`;

const BodyHeader = styled.div`
  margin-left: 20px;
`;

const CompleteGridCodeHeading = styled.h1`
  margin-bottom: 15px;
  margin-top: 0;
`;
const StyledMidContainer = styled.div`
  background-color: #fff;
  padding-top: 17px;
  box-shadow: ${({ isGridCodeTab, activeTab }) =>
    !isGridCodeTab || isGridCodeTab.length === 0 || activeTab === 0
      ? "0px 3px 4px 0px rgba(0, 0, 0, 0.1)"
      : "inherit"};
  position: fixed;
  z-index: 9;
  top: 107px;
  width: ${({ isGlossaryRight, isSidebar }) =>
    !isGlossaryRight && !isSidebar
      ? "calc(100% - 355px)"
      : !isGlossaryRight && isSidebar
      ? "calc(100% - 708px)"
      : isGlossaryRight && !isSidebar
      ? "calc(100% - 2px)"
      : isGlossaryRight && isSidebar
      ? "calc(100% - 355px)"
      : "100%"};
`;

const DownloadButtonWithIcon = styled.div`
  cursor: ${({ downloading }) => (downloading ? "not-allowed" : "pointer")};
  color: ${({ downloading }) => (downloading ? "#8d8d8d" : "#b04a2a")};
  font-weight: 700;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const NextAndPrevSticky = styled.div`
  background-color: #f4f4f4;
  right: 354px;
  z-index: 10;
  padding: 15px;
  box-shadow: ${({ isGridCodeTab, activeTab }) =>
    !!isGridCodeTab && activeTab !== 0
      ? "0px 3px 4px 0px rgba(0, 0, 0, 0.1)"
      : "inherit"};
`;

const LastUpdatedParentDiv = styled.div`
  margin-bottom: 20px;
`;

const ButtonExtend = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

const DownloadText = styled.div`
  transition: 0.3s;
  color: #b04a2a;
  &:hover {
    color: #9e4326;
  }
`;

const Gridcode = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [isGlossaryRight, setIsGlossaryRight] = useState(false);
  const [isMainMenuVisible, setIsMainMenuVisible] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [prevAndNextBtnHide, setPrevAndNextBtnHide] = useState({
    nextButton: true,
    prevButton: false,
  });
  const [contentSearch, setContentSearch] = useState(null);
  const [isContentSearchErr, setIsContentSearchErr] = useState(false);
  const [currentSecIdForTooltip, setCurrentSecIdForTooltip] = useState("");

  const {
    selectedSubsection,
    selectedSectionId,
    sideMenuData,
    sidebarError,
    gcUpdateDate,
    gcSectionArr,
    gcSectionLoading,
    gcSectionError,
    gcSubSectionArr,
    gcSubSectionLoading,
    gcSubSectionError,
    backToGridCodeBtn,
    isSectionOrSubSectionSelected,
    selectSubSectionId,
    isSearchedresultSection,
    contentSearchedValue,
    getActiveTab,
    getGCkeyArr,
    getGCkeyLoading,
    getGCkeyError,
  } = useSelector((state) => ({
    selectedSubsection: state?.subsection?.selectedSubsec,
    selectedSectionId: state?.subsection?.selectedSectionId,
    sideMenuData: state?.sideMenuData?.sideMenuData,
    sidebarError: state?.sideMenuData?.sidebarError,
    gcUpdateDate: state?.gcSectionMenus?.gcUpdateDate,
    gcSectionArr: state?.gcSectionMenus?.gcSectionArr,
    gcSectionLoading: state?.gcSectionMenus?.gcSectionLoading,
    gcSectionError: state?.gcSectionMenus?.gcSectionError,
    gcSubSectionArr: state?.gcSubSectionMenus?.gcSubSectionArr,
    gcSubSectionLoading: state?.gcSubSectionMenus?.gcSubSectionLoading,
    gcSubSectionError: state?.gcSubSectionMenus?.gcSubSectionError,
    backToGridCodeBtn: state?.backGridCodeButton?.isBackToGridCode,
    isSectionOrSubSectionSelected: state?.subsection?.isSectionOrSubSection,
    selectSubSectionId: state?.subsection?.selectedSubSectionId,
    isSearchedresultSection: state?.subsection?.isSearchedResult,
    contentSearchedValue: state?.subsection?.searchedContent,
    getActiveTab: state?.getActiveTab?.activeTab,
    getGCkeyArr: state?.getGCKeySearch?.getGCkeyArr,
    getGCkeyLoading: state?.getGCKeySearch?.getGCkeyLoading,
    getGCkeyError: state?.getGCKeySearch?.getGCkeyError,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (gcSectionError) {
      throw new Error(JSON.stringify(gcSectionError));
    }
    if (gcSubSectionError) {
      throw new Error(JSON.stringify(gcSubSectionError));
    }
    if (getGCkeyError) {
      throw new Error(JSON.stringify(getGCkeyError));
    }
    if (sidebarError) {
      throw new Error(JSON.stringify(sidebarError));
    }
  }, [gcSectionError, gcSubSectionError, getGCkeyError, sidebarError]);

  // Fetch sidebar data
  useEffect(() => {
    dispatch(fetchGridcodeSidebarData());
  }, [dispatch]);

  // Fetch GCSection API
  useEffect(() => {
    dispatch(fetchgcSectionMenusData(selectedSectionId));
  }, [dispatch, selectedSectionId]);

  // Fetch GCSubSection API
  useEffect(() => {
    if (!selectSubSectionId) return;
    dispatch(fetchgcSubSectionMenuData(selectSubSectionId));
  }, [dispatch, selectSubSectionId]);

  useEffect(() => {
    if (getGCkeyArr?.length === 0) {
      setIsContentSearchErr(true);
    }
  }, [getGCkeyArr]);

  const leftArrowCollapseHandler = () => {
    setIsSidebar((isSidebar) => !isSidebar);
  };

  const rightArrowCollapseHandler = () => {
    setIsGlossaryRight((isGlossaryRight) => !isGlossaryRight);
  };

  const parentSelected = Array.isArray(sideMenuData)
    ? sideMenuData?.find((item) => {
        if (item.sec_id === selectedSectionId) {
          if (item.sec_ind === "N" && item.subsec_ind === "Y") {
            return item;
          }
        }
        return undefined;
      })
    : null;

  // Get Complete Grid Code
  const mainMenuClickHandler = () => {
    setIsMainMenuVisible(true);
    dispatch(setSelectedSectionId("CO"));
    setPrevAndNextBtnHide({ prevButton: false, nextButton: true });
    dispatch(setIsSectionOrSubSection({ section: true, subSection: false }));
    dispatch(setSelectedSubSectionId(sideMenuData[0]?.subsec_id));
    dispatch(setIsSearchedResult(false));
    dispatch(setOnContentSearchSectionActive(null));
  };

  const subsectionIds = useMemo(() => {
    return Array.isArray(sideMenuData)
      ? Array.from(new Set(sideMenuData.map((submenu) => submenu.subsec_id)))
      : [];
  }, [sideMenuData]);

  // onClick of Back to Grid Code Button history on Grid Code page
  useEffect(() => {
    if (
      backToGridCodeBtn &&
      Array.isArray(subsectionIds) &&
      subsectionIds.includes(selectSubSectionId)
    ) {
      dispatch(setBacktoGridCode(false));
      setPrevAndNextBtnHide({ nextButton: true, prevButton: true });
      if (isSectionOrSubSectionSelected.section) {
        dispatch(
          setIsSectionOrSubSection({ section: true, subSection: false })
        );
      } else {
        dispatch(
          setIsSectionOrSubSection({ section: false, subSection: true })
        );
      }
    }
  }, [
    backToGridCodeBtn,
    dispatch,
    isSectionOrSubSectionSelected?.section,
    selectSubSectionId,
    subsectionIds,
  ]);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  // Next Button
  const nextSubmenuHandler = () => {
    setIsMainMenuVisible(true);
    // on Load if selectedSectionId value if CO then changing the value else giving onclicked Section value
    if (selectedSectionId === "CO") {
      dispatch(setSelectedSubSectionId(subsectionIds[1]));
      dispatch(setOnContentSearchSectionActive(sideMenuData[0]?.sec_id));
      dispatch(setSelectedSectionId(sideMenuData[1]?.sec_id));
    } else {
      dispatch(setSelectedSubSectionId(parentSelected?.subsec_id));
      dispatch(setSelectedSubsection(parentSelected?.subsec_id));
    }

    // To Get current index of subsection on click of next button
    const currentIndex = subsectionIds.findIndex(
      (id) => id === selectSubSectionId
    );

    dispatch(setIsSectionOrSubSection({ section: false, subSection: true }));

    // To Get subsection index when clicked on section
    const currentParentIndex = subsectionIds.findIndex(
      (id) => id === parentSelected?.subsec_id
    );
    let nextSubSectionId = "";
    if (
      isSectionOrSubSectionSelected.section &&
      !isSectionOrSubSectionSelected.subSection &&
      currentParentIndex !== -1
    ) {
      nextSubSectionId = subsectionIds[currentParentIndex];
      dispatch(setSelectedSubSectionId(nextSubSectionId));
      const findingSubsectionName = sideMenuData.filter(
        (item) => item.subsec_id === nextSubSectionId
      );
      dispatch(
        setOnContentSearchSectionActive(findingSubsectionName[0]?.sec_id)
      );
      dispatch(setSelectedSectionId(findingSubsectionName[0]?.sec_id));
      dispatch(setSelectedSubsection(findingSubsectionName[0]?.subsec_id));
    } else {
      if (currentIndex !== -1 && currentIndex < subsectionIds?.length - 1) {
        const nextIndex = currentIndex + 1;
        nextSubSectionId = subsectionIds[nextIndex];
        dispatch(setSelectedSubSectionId(nextSubSectionId));
        const findingSubsectionName = sideMenuData.filter(
          (item) => item.subsec_id === nextSubSectionId
        );
        dispatch(
          setOnContentSearchSectionActive(findingSubsectionName[0]?.sec_id)
        );
        dispatch(setSelectedSectionId(findingSubsectionName[0]?.sec_id));
        dispatch(setSelectedSubsection(findingSubsectionName[0].subsec_id));
      }
    }

    setPrevAndNextBtnHide({ ...prevAndNextBtnHide, prevButton: true });

    const lastIndex = sideMenuData?.length - 1;
    const lastObj = sideMenuData[lastIndex];

    if (!!lastObj && nextSubSectionId === lastObj.subsection_name) {
      setPrevAndNextBtnHide({ ...prevAndNextBtnHide, nextButton: false });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Previous Button
  const prevSubmenuHandler = () => {
    if (!!parentSelected) {
      dispatch(setSelectedSubSectionId(parentSelected?.subsec_id));
      dispatch(setSelectedSubsection(parentSelected?.subsec_id));
    }

    const currentIndex = subsectionIds.findIndex(
      (id) => id === selectSubSectionId
    );

    // To Get subsection index when clicked on section
    const currentParentIndex = subsectionIds.findIndex(
      (id) => id === parentSelected?.subsec_id
    );

    if (
      isSectionOrSubSectionSelected.section &&
      !isSectionOrSubSectionSelected.subSection &&
      currentParentIndex !== -1
    ) {
      const previousIndexOfSection = currentParentIndex - 1;
      const previousSubSectionId = subsectionIds[previousIndexOfSection];
      dispatch(setSelectedSubSectionId(previousSubSectionId));
      const findingSubsectionName = sideMenuData.filter(
        (item) => item.subsec_id === previousSubSectionId
      );
      dispatch(
        setOnContentSearchSectionActive(findingSubsectionName[0]?.sec_id)
      );
      dispatch(setSelectedSectionId(findingSubsectionName[0]?.sec_id));
      dispatch(setSelectedSubsection(findingSubsectionName[0].subsec_id));
    } else {
      if (currentIndex !== -1 && currentIndex > 0) {
        const previousIndex = currentIndex - 1;
        const previousSubSectionId = subsectionIds[previousIndex];

        dispatch(setSelectedSubSectionId(previousSubSectionId));
        const findingSubsectionName = sideMenuData.filter(
          (item) => item.subsec_id === previousSubSectionId
        );
        dispatch(
          setOnContentSearchSectionActive(findingSubsectionName[0]?.sec_id)
        );
        dispatch(setSelectedSectionId(findingSubsectionName[0]?.sec_id));
        dispatch(setSelectedSubsection(findingSubsectionName[0].subsec_id));
      }
    }

    dispatch(setIsSectionOrSubSection({ section: false, subSection: true }));
    setPrevAndNextBtnHide({ ...prevAndNextBtnHide, nextButton: true });

    if (
      sideMenuData[1]?.sec_id === selectedSectionId &&
      sideMenuData[1]?.subsec_id === selectSubSectionId
    ) {
      dispatch(setIsSectionOrSubSection({ section: true, subSection: false }));
      dispatch(setSelectedSectionId("CO"));
      setPrevAndNextBtnHide({ prevButton: false, nextButton: true });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Subsection will close when The complete Grid Code is 'CO'
  useEffect(() => {
    if (selectedSectionId === "CO")
      dispatch(setOnContentSearchSectionActive(null));
  }, [dispatch, selectedSectionId]);

  // Content Search
  const leftSideSearchHandler = (contentSearch) => {
    if (contentSearch !== null && contentSearch?.length >= 2) {
      dispatch(setSearchedContent(contentSearch));
      dispatch(fetchGetGCKeyDataActions(contentSearch));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const searchedResultsHandler = (item, event) => {
    event.preventDefault();
    window.scrollTo({ top: 0 });
    dispatch(setActivetab(0));
    dispatch(fetchgcSubSectionMenuData(item?.subsection));
    dispatch(setSelectedSubsection(item?.subsection));
    dispatch(setIsSectionOrSubSection({ section: false, subSection: true }));
    dispatch(setSelectedSubSectionId(item?.subsection));
    dispatch(setOnContentSearchSectionActive(item?.section));
    dispatch(setSelectedSectionId(item?.section));
    dispatch(setIsSearchedResult(true));
    !isMainMenuVisible && setIsMainMenuVisible(true);
  };

  const handleDownload = async () => {
    const fileName = "00_The_Full_Grid_Code.pdf";

    setDownloading(true);
    try {
      const response = await fetch("api/GcMain/pdf");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      // TODO : @amrit add appInsight
      console.log("\n--error in downloading--\n", err, "\n----\n");
    } finally {
      setDownloading(false);
    }
  };

  // ============== Middle Body content ============== //
  const renderSectionOrSubsection = () => {
    if (!!isSectionOrSubSectionSelected?.subSection) {
      return <h3>{selectedSubsection}</h3>;
    }
    return null;
  };

  const renderSectionText = () => {
    if (
      !!isSectionOrSubSectionSelected?.section &&
      Array.isArray(gcSectionArr) &&
      gcSectionArr?.length > 0
    ) {
      return gcSectionArr?.map((gcSection) => (
        <ParsedHtmlWithTooltip
          value_text={`<p>${gcSection?.value_text
            ?.replace(/\\"/g, '"')
            ?.replace(/\\\\/g, "\\")}</p>`}
          section={selectedSectionId}
          subSec={gcSection?.subsection}
          key={`${gcSection?.Id}`}
          secId={`${gcSection?.Id}`}
          currentSecIdForTooltip={currentSecIdForTooltip}
          setCurrentSecIdForTooltip={setCurrentSecIdForTooltip}
        />
      ));
    }
    return null;
  };

  const renderSubsectionText = () => {
    if (
      !!isSectionOrSubSectionSelected?.subSection &&
      Array.isArray(gcSubSectionArr) &&
      gcSubSectionArr?.length > 0 &&
      !isSearchedresultSection
    ) {
      return gcSubSectionArr?.map((gcSubSection) => (
        <ParsedHtmlWithTooltip
          key={`${gcSubSection?.Id}`}
          secId={`${gcSubSection?.Id}`}
          currentSecIdForTooltip={currentSecIdForTooltip}
          setCurrentSecIdForTooltip={setCurrentSecIdForTooltip}
          value_text={`<p>${gcSubSection?.value_text
            ?.replace(/\\"/g, '"')
            ?.replace(/\\\\/g, "\\")}</p>`}
          section={selectedSectionId}
          subSec={gcSubSection?.subsection}
        />
      ));
    }
    return null;
  };

  const renderSearchedResult = () => {
    if (isSearchedresultSection) {
      return gcSubSectionArr?.map((gcSubSection) => (
        <ParsedHtmlWithTooltip
          key={`${gcSubSection?.Id}`}
          secId={`${gcSubSection?.Id}`}
          currentSecIdForTooltip={currentSecIdForTooltip}
          setCurrentSecIdForTooltip={setCurrentSecIdForTooltip}
          value_text={`<p>${textSearchHandler(
            gcSubSection?.value_text,
            contentSearchedValue
          )
            ?.replace(/\\"/g, '"')
            ?.replace(/\\\\/g, "\\")}</p>`}
          section={selectedSectionId}
          subSec={gcSubSection?.subsection}
        />
      ));
    }
    return null;
  };

  const oc4 = gcSectionArr?.find((item) => item.section === "OC4");

  const renderButtons = () => (
    <>
      {!oc4 ? (
        <ButtonsParentDiv>
          {prevAndNextBtnHide.prevButton && (
            <ButtonExtend
              primary
              onClick={prevSubmenuHandler}
              dataTestId="previous-button"
              id="prevButton"
              className="flex align-items mt-0"
              type="ICON"
            >
              <img src={PrevArrowWhite} alt="Previous Arrow" />
            </ButtonExtend>
          )}
          {prevAndNextBtnHide.nextButton && (
            <ButtonExtend
              primary
              marg
              className="flex align-items mt-0"
              onClick={nextSubmenuHandler}
              dataTestId="next-button"
              id="nextButton"
              type="ICON"
            >
              <img src={NextArrowWhite} alt="Next Arrow" />
            </ButtonExtend>
          )}
        </ButtonsParentDiv>
      ) : (
        <></>
      )}
    </>
  );

  // ============== Middle Body content End ============== //
  return (
    <StyledMainContainer>
      {isSidebar && (
        <StyledLeftSection>
          <Tabs>
            <div label="GC Navigation" id="gcNavigation">
              <Sidebar
                setPrevAndNextBtnHide={setPrevAndNextBtnHide}
                prevAndNextBtnHide={prevAndNextBtnHide}
                mainMenuClickHandler={mainMenuClickHandler}
                isMainMenuVisible={isMainMenuVisible}
              />
            </div>
            <div label="Search">
              <SideMenuInputDiv>
                <Input
                  value={!!contentSearch ? contentSearch : ""}
                  isEnabled={contentSearch?.length >= 2}
                  isGlossary={true}
                  isError={isContentSearchErr && contentSearch}
                  placeholder="Search the Grid Code"
                  onChange={(e) => {
                    setContentSearch(e.target.value);
                    setIsContentSearchErr(false);
                  }}
                  onClickSearch={() => leftSideSearchHandler(contentSearch)}
                  onEnter={() => leftSideSearchHandler(contentSearch)}
                  type={"search"}
                  data-testid="search-gridcode"
                  id="gcGridCodeSearchInput"
                />
                {isContentSearchErr && contentSearch ? (
                  <StyledSearchBoxErr>
                    Sorry, no match found. Please check for typos or try a
                    different search term.
                  </StyledSearchBoxErr>
                ) : (
                  <></>
                )}
              </SideMenuInputDiv>
            </div>
          </Tabs>
        </StyledLeftSection>
      )}
      <StyledMiddleBlock
        className="middleBlock"
        id="middleBlock"
        isGridCodeView={isSidebar}
        isGlossaryView={!isGlossaryRight}
      >
        <StyledMidContainer
          isGlossaryRight={isGlossaryRight}
          isSidebar={isSidebar}
          isGridCodeTab={getGCkeyArr}
          activeTab={getActiveTab}
        >
          <LastUpdatedParentDiv className="flex justify-content">
            <StyledTitleBlock>
              <div
                onClick={leftArrowCollapseHandler}
                className="cursorPointer"
                id="menuLeftArrow"
                data-testid="left-arrow-button"
              >
                <img
                  src={isSidebar ? arrowLeft : arrowRight}
                  alt="Left Arrow"
                  data-testid="left-arrow"
                  id="menuLeftArrowIcon"
                />
              </div>
              <BodyHeader>
                {isSidebar && !isGlossaryRight ? (
                  <BreadcrumbDropdown links={BreadcrumbDropdownlinks} />
                ) : (
                  <Breadcrumb items={breadcrumbItems} />
                )}
                <CompleteGridCodeHeading>
                  THE COMPLETE GRID CODE
                </CompleteGridCodeHeading>
                <div className="flex align-items" id="lastUpdated">
                  <img
                    src={ClockIcon}
                    alt="Clock Icon"
                    data-testid="clock-icon"
                  />
                  <DateSpan>{`Last Updated: ${gcUpdateDate}`}</DateSpan>
                </div>
              </BodyHeader>
            </StyledTitleBlock>
            <div
              className="cursorPointer"
              onClick={rightArrowCollapseHandler}
              id="rightArrowGlossarySearch"
            >
              <img
                src={isGlossaryRight ? arrowLeft : arrowRight}
                alt="Right Arrow"
                data-testid="right-arrow-collapse"
                id="rightArrowGlossarySearchIcon"
              />
            </div>
          </LastUpdatedParentDiv>
          {getActiveTab === 0 || !getGCkeyArr || getGCkeyArr.length === 0 ? (
            <NextAndPrevSticky className="flex justify-content align-items">
              <div>
                {downloading ? (
                  <DownloadButtonWithIcon
                    downloading={+!!downloading}
                    className="downalodButtonWithIcon"
                    id="downalodButtonWithIcon"
                  >
                    <div>Downloading...</div>
                  </DownloadButtonWithIcon>
                ) : (
                  <DownloadButtonWithIcon
                    downloading={+!!downloading}
                    onClick={handleDownload}
                  >
                    <DownloadText>Download</DownloadText>
                    <img
                      src={DownloadIconSmall}
                      alt="Download Text"
                      className="downloadIcon"
                      id="downloadIcon"
                    />
                  </DownloadButtonWithIcon>
                )}
              </div>
              <div>{renderButtons()}</div>
            </NextAndPrevSticky>
          ) : (
            <></>
          )}
        </StyledMidContainer>
        <BodyMiddleTextComponent
          gcSectionLoading={gcSectionLoading}
          gcSubSectionLoading={gcSubSectionLoading}
          getGCkeyLoading={getGCkeyLoading}
          getActiveTab={getActiveTab}
          contentSearchedValue={contentSearchedValue}
          getGCkeyArr={getGCkeyArr}
          gcSectionArr={gcSectionArr}
          renderSectionOrSubsection={renderSectionOrSubsection}
          renderSectionText={renderSectionText}
          renderSubsectionText={renderSubsectionText}
          renderSearchedResult={renderSearchedResult}
          searchedResultsHandler={searchedResultsHandler}
        />
        <PaddFifteen />
      </StyledMiddleBlock>
      {!isGlossaryRight && <GlossarySideBar />}
    </StyledMainContainer>
  );
};
export default Gridcode;
