import {
  fetchAllGlossaryItemsAPI,
  fetchGlossaryItemsByAlphabatesAPI,
  fetchGlossaryItemsByKeyAPI,
} from "./viewGlossary-api";
import { setItems, setLoading, setError } from "./viewGlossary-slice";

export const fetchGlossaryItems = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await fetchAllGlossaryItemsAPI();
    if (response.hasOwnProperty("error")) {
      dispatch(setError(response.error));
    } else {
      dispatch(setItems(response));
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const fetchGlossaryItemsByAlphabets = (alpha) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await fetchGlossaryItemsByAlphabatesAPI(alpha);
    if (response.hasOwnProperty("error")) {
      dispatch(setError(response.error));
    } else {
      dispatch(setItems(response));
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const fetchGlossaryItemsByKey = (key) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await fetchGlossaryItemsByKeyAPI(key);
    if (response.hasOwnProperty("error")) {
      dispatch(setError(response.error));
    } else {
      dispatch(setItems(response));
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};
