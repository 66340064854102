import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0;
  gap: 8px;
  height: 28px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  li {
    margin-right: 5px;
  }
  a {
    margin-right: 8px;
    color: #b04a2a;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }
  li:last-child span {
    color: #525252;
    font-size: 14px;
  }
`;

const Separator = styled.span`
  margin-right: 5px;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
`;

const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  right: -110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  ul {
    padding: 0;
    display: block;
  }

  ul li {
    display: block;
    padding: 13px 15px;
    text-align: left;
    margin-right: 0;
  }

  ul li a {
    color: #b04a2a !important;
  }

  ul li:hover {
    background-color: #ddd;
  }
`;

const BreadcrumbDropdown = ({ links }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef(null);
  const BASE_URL = process.env.REACT_APP_ENVIRONMENT_BASE_URL;

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <BreadcrumbContainer ref={ref} id="breadcrumbContainer">
      <ul>
        <li>
          <a href={links[0].url}>{links[0].label}</a>
          <Separator>/</Separator>
        </li>
        {links?.length > 2 && (
          <>
            <li>
              <DropdownContainer id="dropdownContainer">
                <DropdownButton id="dropdownButton" onClick={toggleDropdown}>
                  ...
                </DropdownButton>
                {showDropdown && (
                  <DropdownContent id="dropdownContent">
                    <ul>
                      {links.slice(1, -1).map((link) => (
                        <li key={`${link?.url}_${link?.label}`}>
                          <a href={link.url}>{link.label}</a>
                        </li>
                      ))}
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
            <Separator>/</Separator>
          </>
        )}
        <li>
          {" "}
          <Link
            to={`${BASE_URL}/industry-information/codes/grid-code-gc/grid-code-documents`}
          >
            Grid Code documents
          </Link>
        </li>
        <li>
          <span>/ &nbsp;</span>
          <span>{links[links?.length - 1].label}</span>
        </li>
      </ul>
    </BreadcrumbContainer>
  );
};

export default BreadcrumbDropdown;
