import { createSlice } from "@reduxjs/toolkit";

const tabsSlice = createSlice({
  name: "tabs",
  initialState: {
    activeTab: 0,
  },
  reducers: {
    setActivetab(state, action) {
      state.activeTab = action.payload;
    }
  },
});

export const { setActivetab } = tabsSlice.actions;
export default tabsSlice.reducer;
