import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import DownArrow from "../../../Assets/accordionDownArrow.svg";
import UpArrow from "../../../Assets/accordionUpArrow.svg";

const AccordionItemContainer = styled.div`
  border-bottom: 1px solid #d2d2d2;
  &:first-child {
    border-top: 1px solid #d2d2d2;
  }
`;

const AccordionHeader = styled.div`
  padding: 6px 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  text-align: left;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
`;

const AccordionContent = styled.div`
  display: ${(props) => (props.isopen ? "block" : "none")};
`;

const StyledFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPara = styled.p`
  margin: 5px 0;
`;

const AccordionItem = ({ title, children, isopen, onClick, role }) => {
  return (
    <AccordionItemContainer
      role={role}
      className="accordionItemContainer"
      id="accordionItemContainer"
    >
      <AccordionHeader
        onClick={onClick}
        className="accordionHeader"
        id="accordionHeader"
      >
        <StyledFlexBox>
          <StyledFlexBox>
            <div>
              <StyledPara className="accordionTitle" id="accordionTitle">
                {title && parse(title)}
              </StyledPara>
            </div>
          </StyledFlexBox>
          <div isopen={isopen}>
            <img
              className="toggleArrow"
              id="toggleArrow"
              alt="Toggle Arrow"
              src={isopen ? UpArrow : DownArrow}
            />
          </div>
        </StyledFlexBox>
        <AccordionContent
          isopen={isopen}
          className="accordionContent"
          id="accordionContent"
        >
          {children}
        </AccordionContent>
      </AccordionHeader>
    </AccordionItemContainer>
  );
};

export default AccordionItem;
