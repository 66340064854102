import { fetchgetGCKeyData } from "./api";
import {
  setgetGCkeyItems,
  setgetGCkeyLoading,
  setgetGCkeyError,
} from "./slice";

export const fetchGetGCKeyDataActions = (key) => async (dispatch) => {
  try {
    dispatch(setgetGCkeyLoading());
    const response = await fetchgetGCKeyData(key);
    if (response.hasOwnProperty("error")) {
      dispatch(setgetGCkeyError(response.error));
    } else {
      dispatch(setgetGCkeyItems(response));
    }
  } catch (error) {
    dispatch(setgetGCkeyError(error.message));
  }
};
