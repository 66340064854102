import { createSlice } from "@reduxjs/toolkit";

const subsectionSlice = createSlice({
  name: "subsection",
  initialState: {
    selectedSubsec: 'P.1',
    activeSection: null,
    selectedSectionId: "CO",
    isSectionOrSubSection: {
      section: true,
      subSection: false,
    },
    selectedSectionName: null,
    searchedContent: null,
    selectedSubSectionId: null,
    isSearchedResult: false,
  },
  reducers: {
    setSelectedSectionId(state, action) {
      state.selectedSectionId = action.payload;
    },
    setSelectedSubsection(state, action) {
      state.selectedSubsec = action.payload;
    },
    setActiveSectionName(state, action) {
      state.activeSection = action.payload === state.activeSection ? null : action.payload;
      state.selectedSectionName = action.payload;
    },
    setIsSectionOrSubSection(state, action) {
      state.isSectionOrSubSection = action.payload;
    },
    setSearchedContent(state, action) {
      state.searchedContent = action.payload;
    },
    setSelectedSubSectionId(state, action) {
      state.selectedSubSectionId = action.payload;
    },
    setOnContentSearchSectionActive(state, action) {
      state.activeSection = action.payload;
    },
    setIsSearchedResult( state, action) {
      state.isSearchedResult = action.payload;
    }
  },
});

export const {
  setSelectedSubsection,
  setActiveSectionName,
  setSelectedSectionId,
  setIsSectionOrSubSection,
  setSearchedContent,
  setSelectedSubSectionId,
  setIsSearchedResult,
  setOnContentSearchSectionActive
} = subsectionSlice.actions;
export default subsectionSlice.reducer;
