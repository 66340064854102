import React from "react";
import styled from "styled-components";
import EditIcon from "../../Assets/editIcon.svg";
import RevisionIcon from "../../Assets/revisionIcon.svg";
import Loader from "../../Components/Loader";
import SearchResults from "../../Components/SearchResults";

const BodyText = styled.div`
  padding-top: 10px;
  margin-top: 230px;
  padding-bottom: 15px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 17px;
    right: 17px;
    height: 1px;
    background-color: #000;
  }
`;

const PaddFifteen = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #f5f5f5;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #525252;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #525252;
    border-radius: 4px;
  }
  &#middleBodyText p {
    font-size: 16px;
  }
`;

const IssueRevision = styled(PaddFifteen)`
  margin-bottom: 15rem;
`;

const BodyMiddleTextComponent = ({
  gcSectionLoading,
  gcSubSectionLoading,
  getGCkeyLoading,
  getActiveTab,
  contentSearchedValue,
  getGCkeyArr,
  gcSectionArr = [],
  renderSectionOrSubsection,
  renderSectionText,
  renderSubsectionText,
  renderSearchedResult,
  searchedResultsHandler,
}) => {
  let contentToRender = <Loader />;

  if (
    !gcSectionLoading &&
    !gcSubSectionLoading &&
    !getGCkeyLoading &&
    (getActiveTab === 0 || !contentSearchedValue || getGCkeyArr?.length === 0)
  ) {
    contentToRender = (
      <>
        <BodyText
          className="bodyMiddleText tooltipOnHover"
          id="bodyMiddleText"
          data-testid="bodyMiddleText"
        >
          <PaddFifteen id="middleBodyText">
            {renderSectionOrSubsection()}
            {renderSectionText()}
            {renderSubsectionText()}
            {renderSearchedResult()}
          </PaddFifteen>
        </BodyText>
        <IssueRevision>
          <div className="flex justify-content">
            {!!gcSectionArr[0]?.revision_nbr ? (
              <div className="flex">
                <img
                  src={EditIcon}
                  alt="Edit Icon"
                  className="editIcon"
                  id="editIcon"
                />
                <span style={{ marginLeft: "5px" }} id="editText">
                  {gcSectionArr[0]?.revision_nbr}
                </span>
              </div>
            ) : (
              <></>
            )}
            {!!gcSectionArr[0]?.revision_date ? (
              <div className="flex">
                <img
                  src={RevisionIcon}
                  alt="Revision Icon"
                  className="revisionIcon"
                  id="revisionIcon"
                />
                <span style={{ marginLeft: "5px" }} id="revisionText">
                  {gcSectionArr[0]?.revision_date}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </IssueRevision>
      </>
    );
  } else if (
    !(getActiveTab === 0 || !contentSearchedValue || getGCkeyArr?.length === 0)
  ) {
    contentToRender = (
      <SearchResults
        searchedArr={getGCkeyArr || []}
        contentSearchedValue={contentSearchedValue}
        searchedResultsHandler={searchedResultsHandler}
      />
    );
  }

  return contentToRender;
};

export default BodyMiddleTextComponent;
