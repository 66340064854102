export const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const textSearchHandler = (str, substr) => {
  if (typeof substr !== "string") {
    substr = String(substr);
  }

  const pattern = new RegExp(
    substr
      // eslint-disable-next-line no-useless-escape
      .replace(/([.*+?^${}()|\[\]\\])/g, "\\$1")
      .replace(/ /g, "\\s*(<[^>]*>)?\\s*"),
    "gi"
  );

  const matches = str.match(pattern);
  const uniqueMatches = [...new Set(matches || [])];

  let replaced = str;
  (uniqueMatches || []).forEach((match) => {
    replaced = replaced.replaceAll(
      match,
      `<span style="background-color: #c0e2bd; font-weight: 400; padding: 2px; border-radius: 2px;"><strong>${match}</strong></span>`
    );
  });

  return replaced;
};

export const generateRandomNo = () => {
  return Math.floor(Math.random() * (9999 - 999 + 1)) + 999;
};
