// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Assets/fonts/texgyreheroscn-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Assets/fonts/texgyreheroscn-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Assets/fonts/texgyreheros-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Assets/fonts/texgyreheros-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "tex_gyre_herosregular", sans-serif, arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: "tex_gyre_heros_cnregular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
      format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tex_gyre_herosregular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,uDAAuD;EACvD,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uCAAuC;EACvC;;0DAE0E;EAC1E,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC;0DACwE;EACxE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"tex_gyre_herosregular\", sans-serif, arial;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  line-height: 1.5;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nimg {\n  max-width: 100%;\n}\n\n@font-face {\n  font-family: \"tex_gyre_heros_cnregular\";\n  src: url(\"./Assets/fonts/texgyreheroscn-regular-webfont.woff2\")\n      format(\"woff2\"),\n    url(\"./Assets/fonts/texgyreheroscn-regular-webfont.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"tex_gyre_herosregular\";\n  src: url(\"./Assets/fonts/texgyreheros-regular-webfont.woff2\") format(\"woff2\"),\n    url(\"./Assets/fonts/texgyreheros-regular-webfont.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
