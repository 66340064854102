import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "gridCodeData",
  initialState: {
    sideMenuData: [],
    sidebarDataloading: false,
    sidebarError: null,
  },
  reducers: {
    setSidebarItems: (state, action) => {
      state.sideMenuData = action.payload;
      state.loading = false;
      state.error = null;
    },
    setSidebarLoading: (state) => {
      state.loading = true;
    },
    setSidebarError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  },
});

export const {
  setSidebarItems,
  setSidebarLoading,
  setSidebarError,
} = sidebarSlice.actions;
export default sidebarSlice.reducer;
