import React from "react";
import styled from "styled-components";

const Noresults = styled.div`
  background-color: #f4f4f4;
  height: 140px;
  width: 100%;
  line-height: 140px;
  text-align: center;
  margin-top: 10px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InformationIcon = styled.span`
  background: #3394cd;
  height: 24px;
  width: 24px;
  color: #fff;
  border-radius: 50px;
  margin-right: 10px;
  line-height: 24px;
`;

const NoResultsFound = () => {
  return (
    <Noresults
      data-testid="no-results-div"
      id="noResults"
      className="noResults"
    >
      <FlexDiv data-testid="flex-div">
        <InformationIcon data-testid="information-icon">!</InformationIcon>
        <span>No results found</span>
      </FlexDiv>
    </Noresults>
  );
};

export default NoResultsFound;
