import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import ViewGlossaryPage from "./Page/ViewglossaryPage";
import ErrorLayout from "./Page/Error";
import Home from "./Page/Home";
import RootLayout from "./Page/Root";
import ScrollToTopButton from "./Components/BackToTop";
import withAppInsights from "./AppInsights";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/viewGlossary", element: <ViewGlossaryPage /> },
    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
      <ScrollToTopButton />
    </div>
  );
};

export default withAppInsights(App);
