import React from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Mainmenu from "../Components/Mainmenu";
import FallbackError from "./FallbackError";

import Footer from "../Components/Footer";
const RootLayout = () => {
  return (
    <ErrorBoundary
      fallbackRender={FallbackError}
      onReset={(details) => {
        console.log(details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="position-relative">
        <Mainmenu />
        <Outlet />
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default RootLayout;
