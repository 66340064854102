import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { escapeRegExp, generateRandomNo } from "../../utils/utils";

const AutoSugUl = styled.ul`
  position: absolute;
  left: 1px;
  top: 23px;
  background-color: #f2f2f2;
  border: 1px solid #8d8d8d;
  padding: 15px;
  width: calc(100% - 84px);
  color: #525252;
`;

const AutoSugLi = styled.li`
  list-style: none;
  padding-bottom: 16px;
  font-size: 14px;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0;
  }
`;

const AutofillDropdown = ({
  autofillItems = [],
  onItemClick,
  searchText,
  visible,
  setVisible,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });
  return visible ? (
    <AutoSugUl
      ref={ref}
      data-testid="autofill-dropdown"
      className="autoSugUl"
      id="autoSugUl"
    >
      {autofillItems?.map((autofillItem) => {
        return (
          <AutoSugLi
            key={`${autofillItem}_${generateRandomNo()}`}
            onClick={() => onItemClick(autofillItem)}
            className="autoSugLi"
            id="autoSugLi"
          >
            <span>
              {autofillItem !== "\\[\\mathbf{\\Delta f}\\]"
                ? autofillItem
                    .split(new RegExp(`(${escapeRegExp(searchText)})`, "gi"))
                    .map((part) => {
                      const fragmentKey = `${part}_${generateRandomNo()}`;
                      return (
                        <React.Fragment key={fragmentKey}>
                          {part.toLowerCase() === searchText.toLowerCase() ? (
                            <strong>{part}</strong>
                          ) : (
                            part
                          )}
                        </React.Fragment>
                      );
                    })
                : autofillItem}
            </span>
          </AutoSugLi>
        );
      })}
    </AutoSugUl>
  ) : (
    <></>
  );
};

export default AutofillDropdown;
