// Breadcrumb.js
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled components
const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0;
  gap: 8px;
  height: 28px;

  &.BreadcrumbGridCode {
    padding: 0;
  }

  span {
    color: #525252;
    font-size: 14px;
  }
`;

const BreadcrumbItem = styled(Link)`
  margin-right: 5px;
  cursor: ponter;
  color: #b04a2a;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: ${(props) => (props.onClick ? "underline" : "none")};
  }
`;

// Breadcrumb component
const Breadcrumb = ({ items, className }) => {
  return (
    <BreadcrumbContainer className={className} id="breadcrumbContainer">
      {items.map((item, index, arr) => (
        <React.Fragment key={item.text}>
          {index > 0 && <span>/</span>}
          {arr?.length - 1 === index ? (
            <span>{item.text}</span>
          ) : (
            <BreadcrumbItem
              className="m-0 breadcrumbItem"
              id="breadcrumbItem"
              to={item.url}
            >
              {item.text}
            </BreadcrumbItem>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
