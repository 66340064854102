import { createSlice } from "@reduxjs/toolkit";

const gridCodeSlice = createSlice({
  name: "gridCodeData",
  initialState: {
    gridCodeDataArr: [],
    gridCodeloading: false,
    gridCodeerror: null,
  },
  reducers: {
    setGridCodeItems: (state, action) => {
      state.gridCodeDataArr = action.payload;
      state.loading = false;
      state.error = null;
    },
    setGridCodeLoading: (state) => {
      state.loading = true;
    },
    setGridCodeError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setGridCodeItems,
  setGridCodeLoading,
  setGridCodeError,
} = gridCodeSlice.actions;
export default gridCodeSlice.reducer;
